import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

require("../assets/img/success3.webp");
const SuccessPage = () => {
  const [giftStatus, setGiftStatus] = useState<boolean>(false);
  const navigate = useNavigate();
  return (
    <div className="Success__page">
      <div className="container">
        <div className="inform__container">
          <img
            className="successpayment__icon"
            src={require("../assets/img/success3.webp")}
            alt=""
          />
          <div className="inform">
            <h1>Оплата прошла успешлно</h1>
            <h2>Данные для входа были отпавлены вам на почту</h2>
            {/* <p>
              Если игра поддерживает автогвард ,то вы иго сможите получить нажав
              на молнию
            </p> */}
            <p>
              Если для входа у вам потребуют SteamGuard:
              <br />1 Если у игры есть метка auto - то его можно получить
              автоматически нажав на молнию
              <br />
              2.Если нет ,то напишите об этом в чат на нашем сайте и мы его вам
              вышлем
            </p>
          </div>
        </div>
        <div className="wishes">
          <h1>Приятной игры</h1>
          <img src={require("../assets/img/pcmn.webp")} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
