import { FC, memo, useState } from "react";
import Other from "../../store/Other";
import RegUser from "../../store/RegUser";
import { IRegisteredUsers } from "../../types/types";

const UserRef = ({ userData }: any) => {
  const { currency, convertedMoney } = Other;
  const { createPromo } = RegUser;
  const [userPromo, setUserPromo] = useState("");

  return (
    <div className="user-page__container-ref">
      <div className="user-page__container-ref__header">
        РЕФЕРАЛЬНЫЕ ДАННЫЕ
        <div className="user-page__container-ref__header__line"></div>
      </div>
      <div className="user-page__container-ref__wrapper">
        <div className="user-page__container-ref__wrapper-stat">
          <div className="stat-item">
            ВАШ ПРОМОКОД
            {userData.promocode ? (
              <div className="stat-item__user-promo">{userData.promocode}</div>
            ) : (
              <div
                onClick={() => createPromo()}
                className="stat-item__promo-btn"
              >
                СОЗДАТЬ
              </div>
            )}
          </div>
          <div className="stat-item">
            ПРОЦЕНТНАЯ СТАВКА<span> 20 %</span>
          </div>
          <div className="stat-item">
            СКИДКА ДРУГУ<span> 10 %</span>
          </div>

          <div className="stat-item">
            РЕФЕРАЛОВ <span>0</span>
          </div>
          <div className="stat-item">
            ДОХОД <span> {convertedMoney(userData.totalIncome)}</span>{" "}
            {currency}
          </div>
        </div>
        <div className="user-page__container-ref__wrapper__list">
          НЕТ ТРАНЗАКЦИЙ
        </div>
      </div>
    </div>
  );
};

export default memo(UserRef);
