import { memo, useEffect, useState } from "react";

interface Props {
  mobileContent: any;
  desktopContent: any;
}

const MatchMediaWrapper = ({ mobileContent, desktopContent }: Props) => {
  const [isNarrowScreen, setIsNarrowScreen] = useState<boolean>(false);
  useEffect(() => {
    const mediaWatcher = window.matchMedia("(max-width: 700px)");
    setIsNarrowScreen(mediaWatcher.matches);

    function updateIsNarrowScreen(e: any) {
      setIsNarrowScreen(e.matches);
    }
    if (mediaWatcher.addEventListener) {
      mediaWatcher.addEventListener("change", updateIsNarrowScreen);
      return function cleanup() {
        mediaWatcher.removeEventListener("change", updateIsNarrowScreen);
      };
    } else {
      mediaWatcher.addListener(updateIsNarrowScreen);
      return function cleanup() {
        mediaWatcher.removeListener(updateIsNarrowScreen);
      };
    }
  });

  return isNarrowScreen ? mobileContent : desktopContent;
};

export default memo(MatchMediaWrapper);
