import { motion } from "framer-motion";
import { useEffect } from "react";
import Seo from "../../Components/Seo";
import Other from "../../store/Other";
import "./ShopPS.scss";
import ShopPSList from "./ShopPSList";

const ShopPs = () => {
  const { setActivePage } = Other;

  useEffect(() => {
    setActivePage(11);
  }, []);

  
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.7 } }}
      exit={{ opacity: 0, transition: { duration: 0.25 } }}
      onClick={() => console.log(window.scrollY)}
    >
      <Seo
        title="Аренда игр для PS4 и PS5"
        description="Широкий выбор аккаунтов с играми для PlayStation 4 и PlayStation 5 ждет вас в нашем магазине. Новинки и любимая классика уже доступны для аренды. Начните играть прямо сейчас!"
      />
      <div className="ps-shop-page">
        <div className="ps-shop-page__container">
          <ShopPSList />
        </div>
      </div>
    </motion.div>
  );
};

export default ShopPs;
