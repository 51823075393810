import { useSearchParams } from "react-router-dom";
import Seo from "../../Components/Seo";
import "./instruction.scss";
import { useEffect } from "react";

const Instruction = () => {
  const [searchParams] = useSearchParams();
  const service = searchParams.get("service");
  const isOnline = searchParams.get("isOnline");
  const autoGuard = searchParams.get("auto");
  const ggs = searchParams.get("auto");

  useEffect(() => {
    if(ggs){
      return
      localStorage.setItem('utm_campaign','GGSELL')
    }
  },[])

  return (
    <div className="instruction-page">
      <Seo
        title="Инструкция"
        description="Инструкция формируется автоматически в зависимости от приобретенного товара. "
      />
      <div className="instruction-page__container">
        {service === "steam" && (
          <div className="steam">
            <h1 onClick={() => console.log(autoGuard)}>
              Инструкция для Steam - {isOnline == "true" ? "online" : "offline"}
            </h1>
            <section className="instruction-page__step">
              <h3>
                <span> 1.</span> После оплаты зайдите в ваш почтовый ящик,
                который вы указывали перед покупкой. (Так же проверьте папку
                "спам")
              </h3>
              <img
                src={require("./../../assets/instruction/steam1.jpg")}
                alt=""
              />
            </section>
            <section className="instruction-page__step">
              <h3>
                2. Зайдите в{" "}
                <a href="https://store.steampowered.com/about/Steam?l=russian">
                  Steam
                </a>{" "}
                используя данныe для входа, которые были высланы вам на почту, а
                также поставьте галочку возле "запомнить меня" (не через
                браузер)
              </h3>
              <img
                src={require("./../../assets/instruction/steam2.jpg")}
                alt=""
              />
            </section>
            {autoGuard == "true" ? (
              <>
                <section className="instruction-page__step">
                  <h3>
                    3. После успешного ввода данных у вас появится поле,
                    запрашивающее Guard-код.
                  </h3>
                  <h3 style={{ marginTop: "5px" }}>
                    Так как игра, которую вы приобрели, поддерживает{" "}
                    <a href="/guards">autoGuard</a> , вы сможете получить код
                    автоматически
                  </h3>
                  <img
                    src={require("./../../assets/instruction/steam3.jpg")}
                    alt=""
                  />
                </section>
                <section className="instruction-page__step">
                  <h3>
                    3.1 Перйдите на страницу <a href="/guards">autoGuard</a> по
                    ссылке или нажав на молнию и введите в поле вашу почту.
                  </h3>
                  <img
                    src={require("./../../assets/instruction/steam8.jpg")}
                    alt=""
                  />
                  <img
                    src={require("./../../assets/instruction/steam9.jpg")}
                    alt=""
                  />{" "}
                </section>
                <section>
                  <h3>3.2 Также вы можете получить его написав в чат</h3>
                  <img
                    src={require("./../../assets/instruction/steam5.jpg")}
                    alt=""
                  />
                </section>
              </>
            ) : (
              <section className="instruction-page__step">
                <h3>
                  3. Если после успешного ввода данных у вас высветится поле,
                  которое попросит Guard-код, вы можете его получить написав в
                  чат
                </h3>
                <img
                  src={require("./../../assets/instruction/steam3.jpg")}
                  alt=""
                />
                <img
                  src={require("./../../assets/instruction/steam5.jpg")}
                  alt=""
                />
              </section>
            )}
            {isOnline !== "true" && (
              <section className="instruction-page__step">
                <h3>
                  4. Так как игра, которую вы приобрели, относится к категории
                  offline, то после установки игры вам потребуется осуществить
                  первый вход в игру, дождаться игровой заставки, после чего
                  выйти и перейти в автономный режим.
                </h3>
                <h4>
                  В автономный режим нужно перейти обязательно, чтобы не
                  столкнуться с проблемами сохранений. Также учтите, что в этом
                  режиме все ваши сохранения будут храниться на вашем ПК,
                  поэтому мы не рекомендуем удалять и переустанавливать ваш
                  Windows.
                </h4>
                <img
                  src={require("./../../assets/instruction/steam6.png")}
                  alt=""
                />
                <img
                  src={require("./../../assets/instruction/steam7.png")}
                  alt=""
                />
              </section>
            )}{" "}
            <section className="instruction-page__step">
              <h2>Приятной игры )</h2>
            </section>
          </div>
        )}

        {service === "epicgames" && (
          <div className="epicgames">
            <h1 onClick={() => console.log(autoGuard)}>
              Инструкция для Epic Games -{" "}
              {isOnline == "true" ? "online" : "offline"}
            </h1>
            <section className="instruction-page__step">
              <h3>
                <span> 1.</span> После оплаты зайдите в ваш почтовый ящик,
                который вы указывали перед покупкой. (Так же проверьте папку
                "спам")
              </h3>
              <img
                src={require("./../../assets/instruction/steam1.jpg")}
                alt=""
              />
            </section>
            <section className="instruction-page__step">
              <h3>
                2. Зайдите в{" "}
                <a href="https://store.epicgames.com/ru/download">Epic Games</a>{" "}
                используя данныe для входа, которые были высланы вам на почту, а
                также поставьте галочку возле "запомнить устройство" (не через
                браузер)
              </h3>
              <div className="instruction-page__step__img-wrapper">
                <img
                  src={require("./../../assets/instruction/epic1.png")}
                  alt=""
                />
                <img
                  src={require("./../../assets/instruction/epic2.png")}
                  alt=""
                />
                <img
                  src={require("./../../assets/instruction/epicc2.png")}
                  alt=""
                />
              </div>
            </section>
            {autoGuard == "true" ? (
              <section className="instruction-page__step">
                <h3>
                  3.1 Перйдите на страницу <a href="/guards">autoGuard</a> по
                  ссылке или нажав на молнию и введите в поле вашу почту.
                </h3>
                <img
                  src={require("./../../assets/instruction/steam8.jpg")}
                  alt=""
                />
                <img
                  src={require("./../../assets/instruction/steam9.jpg")}
                  alt=""
                />{" "}
              </section>
            ) : (
              <section>
                <h3>3.2 Также вы можете получить его написав в чат</h3>
                <img
                  src={require("./../../assets/instruction/steam5.jpg")}
                  alt=""
                />
              </section>
            )}
            {isOnline !== "true" && (
              <section className="instruction-page__step">
                <h3>
                  4. Так как игра, которую вы приобрели, относится к категории
                  offline, то после установки игры вам потребуется осуществить
                  первый вход в игру, дождаться игровой заставки, после чего
                  выйти и перейти в автономный режим.
                </h3>
                <h4>
                  В автономный режим нужно перейти обязательно, чтобы не
                  столкнуться с проблемами сохранений. Также учтите, что в этом
                  режиме все ваши сохранения будут храниться на вашем ПК,
                  поэтому мы не рекомендуем удалять и переустанавливать ваш
                  Windows.
                </h4>
                <img
                  src={require("./../../assets/instruction/epicGames1.png")}
                  alt=""
                />
                <img
                  src={require("./../../assets/instruction/epicGames2.png")}
                  alt=""
                />
                <img
                  src={require("./../../assets/instruction/epicGames3.png")}
                  alt=""
                />
              </section>
            )}{" "}
            <section className="instruction-page__step">
              <h2>Приятной игры )</h2>
            </section>
          </div>
        )}
      </div>
    </div>
  );
};

export default Instruction;
