import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { games } from "../../Products/games";
import Other from "../../store/Other";
import cl from "./gameAccountItem.module.scss";

const GameAccountItem = observer(({ item, custom }: any) => {
  const navigate = useNavigate();
  const { currency } = Other;

  const itemsAnimation = {
    hidden: {
      y: 27,
      opacity: 0,
    },

    visible: () => ({
      y: 0,
      opacity: 1,
      transition: { delay: (custom % 8) * 0.05 },
    }),
  };

  const currentPrice = useMemo(() => {
    switch (currency) {
      case "₴":
        return item.price.uah;
      case "$":
        return item.price.usd;

      default:
        return item.price.r;
    }
  }, [currency]);

  return (
    <motion.div
      style={{ backgroundImage: `url(${item.img})` }}
      className={cl.shop__item}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={itemsAnimation}
      onClick={() => navigate(`/gameaccounts/${item.id}`)}
    >
      <div className={cl.inform__container}>
        <div className={cl.infrom}>
          <span>{item.title}</span>

          <div className={cl.price}>
            <p> {item.platform}</p>
            <p>
              {currentPrice}
              {currency}
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
});

export default GameAccountItem;
