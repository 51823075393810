import { FC, useState } from "react";
import Skeleton from "react-loading-skeleton";

const PSGameAbout: FC<any> = ({ product }) => {
  const [selectedTab, setSelectedTab] = useState(1);
  return (
    <div className="content-container__about-wrapper">
      <div className="content-container__about-head">
        <div
          id={
            selectedTab == 1
              ? "content-container__about-head__tab_selected"
              : ""
          }
          onClick={() => setSelectedTab(1)}
          className="content-container__about-head__tab"
        >
          Об игре
        </div>
        <div
          id={
            selectedTab == 2
              ? "content-container__about-head__tab_selected"
              : ""
          }
          onClick={() => setSelectedTab(2)}
          className="content-container__about-head__tab"
        >
          Активация
        </div>

        <div
          id={
            selectedTab == 3
              ? "content-container__about-head__tab_selected"
              : ""
          }
          onClick={() => setSelectedTab(3)}
          className="content-container__about-head__tab"
        >
          Условия
        </div>
      </div>
      <div
        style={{ borderRadius: selectedTab == 1 ? "" : "8px" }}
        className="content-container__about-inform"
      >
        <p>{!product && <Skeleton count={5} />}</p>
        {product && selectedTab == 1 && (
          <>
            <h3>{product.description.title}</h3>
            <p>{product.description.text}</p>
          </>
        )}
        {product && selectedTab == 2 && (
        product.isOnline ?
        <>
        <div className="about-inform__item">
            <strong>1</strong>
            <li>
              Создайте нового пользователя(не гость на <span> Ps4</span> и не
              однократный пользователь на <span>Ps5</span>), при включении
              приставки или долгим нажатием кнопки "PS";
            </li>
          </div>
          <div className="about-inform__item">
            <strong>2</strong>
            <li>
              Войдите в него используя логин и пароль, которые вы получите на
              почту после покупки
            </li>
          </div>
          <div className="about-inform__item">
            <strong>3</strong>
            <li>
              Приставка предлагает активировать аккаунт или включить общий
              доступ: <span>НЕОБХОДИМО НАЖАТЬ НЕТ</span>
            </li>
          </div>
          <div className="about-inform__item">
            <strong>4</strong>
            <li>
              Перейдите в библиотеку, зайдите в раздел "Приобретено" и начните
              загрузку игры.
            </li>
          </div>
          <div className="about-inform__item">
            <strong>5</strong>
            <li>После загрузки вы сможете начать наслаждаться игрой</li>
          </div>
        </>
        :
        <>
          <div className="about-inform__item">
            <strong>1</strong>
            <li>
            Заходите на аккаунт с купленной игрой.
            </li>
          </div>
          <div className="about-inform__item">
            <strong>2</strong>
            <li>
            PS 5 - Включаете общий доступ / PS4 - Делаете систему как основную
            </li>
          </div>

          <div className="about-inform__item">
            <strong>3</strong>
            <li>
            Скачиваете игру  (во время загрузки переходите на свой ps аккаунт)
            </li>
          </div>

          <div className="about-inform__item">
            <strong>4</strong>
            <li>
            Отключаете интернет на консоли.
            </li>
          </div>

          <div className="about-inform__item">
            <strong>5</strong>
            <li>
            Можете начинать играть с выключеным интернетом
            </li>
          </div>

          

        </>
        )}
        {product && selectedTab == 3 && (

          product.isOnline?
          <>
            <li className="about-inform__condition">
              - Запрещено менять данные аккаунта. Попытка смены данных приведет
              к немедленной блокировке аккаунта.
            </li>
            <li className="about-inform__condition">
              {" "}
              - Запрещено заходить на учетную запись через браузер компьютера.
            </li>
            <li className="about-inform__condition">
              - Аккаунт устанавливается только на одну консоль.
            </li>
          </>
          :
          <>
          <li className="about-inform__condition">
            - Запрещено менять данные от учетной записи
          </li>
          {/* <li className="about-inform__condition">
            - Запрещено делать аккаунт домашним (включать "Общий доступ"/ делать систему основной)
          </li> */}

          <li className="about-inform__condition">
            - Запрещено заходить в аккаунт через браузер, или с любого устройства, кроме консоли
          </li>
          
          </>
            
        )}
      </div>
    </div>
  );
};

export default PSGameAbout;
