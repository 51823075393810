import axios from "axios";
import { makeAutoObservable } from "mobx";
import { IRegisteredUsers } from "../types/types";

class UserReg {
  userData: IRegisteredUsers | null = null;
  constructor() {
    makeAutoObservable(this);
  }

  getUserData = async (userId: any) => {
    const userData = await axios.post(
      // "http://localhost:8080/user/getRUserData",
      "https://api.steam-rent.com/user/getRUserData",
      {
        userId: userId !== null ? userId : localStorage.getItem("userId"),
      }
    );
    this.userData = userData.data.userData;
  };

  createPromo = async () => {
    const newPromo = await axios
      .post("https://api.steam-rent.com/user/createPromo", {
        userId: localStorage.getItem("userId"),
      })
      .then((resp) => resp.data.newPromo);
    this.getUserData(null);
  };
}

export default new UserReg();
