import React from "react";

const VideoYb: React.FC<{ videoLink: string }> = ({ videoLink }) => {
  return (
    <div style={videoLink ? {} : { display: "none" }} className="video">
      <iframe src={videoLink} frameBorder="0" allowFullScreen={true}></iframe>
    </div>
  );
};

export default React.memo(VideoYb);
