import axios from "axios";
import { makeAutoObservable } from "mobx";

class Other {
  activePage: number = 1;
  initialC: string = "";
  currency: string = "$";
  isSidebarActive: boolean = false;
  isAuth: boolean = false;
  userEmail:string = '';
  suggestionBannerVisibility:boolean = false

  constructor() {
    makeAutoObservable(this);
  }

  auth = (userId: string) => {
    this.isAuth = true;
    localStorage.setItem("userId", userId);
  };

  logOut = () => {
    localStorage.removeItem("userId");
    this.isAuth = false;
  };

  openSideBar = (status: boolean) => {
    console.log(status);
    this.isSidebarActive = status;
  };

  setActivePage = (page: number) => {
    this.activePage = page;
  };

  setUserEmail = (text:string) => {
    this.userEmail = text
  }

  changeCurrecy() {
    // console.log(this.initialC);
    switch (this.currency) {
      case "₴":
        this.currency = this.initialC == "UA" ? "$" : "$";
        break;
      case "$":
        this.currency = ["MD", "RU"].includes(this.initialC) ? "₽" : "₽";
        // this.currency = this.initialC != "RU" ? "₴" : "₽";
        // this.currency = "₽";
        break;
      case "₽":
        this.currency = "₴";
        break;
      case "€":
        this.currency = "₴";
        break;
      default:
        this.currency = "$";
        break;
    }
  }
  defineCountry = async () => {
    // return;
    try {
      const country = await axios
        .get("https://ipinfo.io")
        .then((resp) => resp.data.country);
      country == "UA"
        ? (this.currency = "₴")
        : country == "RU"
        ? (this.currency = "₽")
        : (this.currency = "$");
      this.initialC = country;
      // const userData = await axios
      //   .get("https://ipinfo.io")
      //   .then((resp) => resp.data);

      // axios.post("https://api.steam-rent.com/log", {
      //   text: userData,
      // });

      const referrer = document.referrer;
      if (localStorage.getItem("userEmail") !== "test@gmail.com") {
        axios.post("https://api.steam-rent.com/log", {
          text: `Someone visited the site from ${country} / ${referrer}`,
        });
      }
    } catch (e) {
      console.log("def err");
    }
  };

  logForServer = async ({ title, action }: any) => {
    if (localStorage.getItem("userEmail") === "test@gmail.com") {
      return;
    }
    axios.post("https://api.steam-rent.com/plainlog", {
      text: `${action}  - ${title} ${this.initialC}- ${
        localStorage.getItem("userEmail")
          ? localStorage.getItem("userEmail")
          : "new"
      } - ${this.isAuth && "reg"} ${localStorage.getItem('utm_campaign') && `- ${localStorage.getItem('utm_campaign')}`}`,
    });
  };

  convertedMoney = (amount: number) => {
    switch (this.currency) {
      case "$":
        return amount;
      case "₴":
        return amount * 37;
      default:
        return amount * 80;
    }
  };


  identifyTrafficSource  = (searchParams:any) => {
    // console.log('identifyTrafficSource')
    if(sessionStorage.getItem('utm_campaign')){
      console.log('ss')
      return
    }
    const blogerName = searchParams.get('utm_campaign')

    if(blogerName){
      console.log('exist')
      axios.post(`${process.env.REACT_APP_ServerLink}/newClick`,{name:blogerName, unique:localStorage.getItem('utm_campaign') ? false:true})
      localStorage.setItem('utm_campaign',blogerName)
      sessionStorage.setItem('utm_campaign',blogerName)
    }

  }
  

  changeBannerVisibility = (newParam:boolean) => {
    if(!newParam){
      sessionStorage.setItem('banner','seen') 
      this.suggestionBannerVisibility= newParam
      return
    }
    if(sessionStorage.getItem('banner') != 'seen'){
      this.suggestionBannerVisibility= newParam
    }
  }
}

export default new Other();
