import React, { useEffect, useState } from 'react'
import cl from './AllCollectionsPage.module.scss'
import { useNavigate } from 'react-router-dom'
import Other from '../../store/Other'

const AllCollectionsPage = () => {
    const navigate = useNavigate()
    interface IcollecationItem {
        title:string
        gameCount:number
        img:string
        url?:string
    }
    const [games, setGames] = useState<IcollecationItem[]>([
        { title: 'Нелинейность', gameCount: 12, img: 'https://img.gg.deals/2d/ec/7bc5c12c346b829c46453185ca15f872653b_912cr476.jpg',url:'nonlinearity'},
        { title: 'Открытый Мир', gameCount: 20, img: 'https://assetsio.gnwcdn.com/best-open-world-games.jpg?width=1200&height=900&fit=crop&quality=100&format=png&enable=upscale&auto=webp',url:'open-world' },
        { title: 'Сильный Сценарий', gameCount: 15, img: `https://www.denofgeek.com/wp-content/uploads/2023/04/Collage-Maker-25-Apr-2023-02-39-PM-2342.jpg?resize=768%2C432`,url:'with-plot'},
        { title: 'Для облачного гейминга',url:'cloud-gaming', gameCount: 35, img: 'https://playua.net/wp-content/uploads/2020/03/4918f55ec8d0976d7833db3ebcde30f5.jpeg' },
        { title: 'Выживание',url:'survivor', gameCount: 12, img: 'https://static0.gamerantimages.com/wordpress/wp-content/uploads/2021/04/survival-games-valheim-the-long-dark-minecraft.jpg' },
        { title: 'Строим Города',url:'city-building', gameCount: 5, img: 'https://icegames.store/5be05dcf-9246-4bae-9a4c-09635b78c827.jpg', },
        { title: 'Космос',url:'space', gameCount: 6, img: 'https://icegames.store/0f7ec523-b448-49d7-9324-d56e183f8e06.jpg' },
        { title: 'Приключение',url:'adventure-games', gameCount: 12, img: 'https://images.pushsquare.com/6dba415973c43/best-adventure-games-on-ps5.large.jpg' },
        // { title: 'Крутая стрельба', gameCount: 13, img: 'https://eu-images.contentstack.com/v3/assets/blt740a130ae3c5d529/blt1053a4287b4e614e/650f0aa230cef76b77cc2aaf/bf.jpg?width=850&auto=webp&quality=95&format=jpg&disable=upscale' },

      ]);


      useEffect(() => {
        Other.setActivePage(3)
      },[])
          return(
        <div className={cl.all_collections_page}>
            <div className={cl.cover}>
                {/* <img src="https://icegames.store/img/selectionsBackground.png" alt="" /> */}
                {/* <img src="" alt="" /> */}
            </div>
           <div className={cl.content_container}>
                <h1>ПОДБОРКА ИГР ДЛЯ ВАС</h1>
                <p className={cl.description}>Мы собрали наши лучшие игры в различные подборки, чтобы вы могли легко выбрать то, что подходит именно вам. Мы постарались создать эти подборки, основываясь на нашем личном мнении, но всегда рады вашим рекомендациям и отзывам</p>
                <div className={cl.collections}>
                    {games.map((item, i) => {
                        if (i % 2 === 0 && i + 1 < games.length) {
                            const isEvenPair = (i / 2) % 2 === 0;
                        return (
                            <React.Fragment key={i}>
                            {isEvenPair ? (
                                <>
                                <div onClick={() => navigate(item.url as string)} className={cl.collection_item}>
                                    <img src={item.img} alt="" />
                                    <div className={cl.collection_info}>
                                        <div className={cl.collection_info_title}>{item.title}</div>
                                        <div className={cl.collection_info_gameCount}>Игр в подборке: {item.gameCount}</div>
                                    </div>
                                </div>
                                <div onClick={() => navigate(games[i + 1].url as string)}  className={cl.collection_item_long}>
                                    <img src={games[i + 1].img} alt="" />
                                    <div className={cl.collection_info}>
                                        <div className={cl.collection_info_title}>{games[i + 1].title}</div>
                                        <div className={cl.collection_info_gameCount}>Игр в подборке:  {games[i + 1].gameCount}</div>
                                    </div>
                                </div>
                                </>
                            ) : (
                                <>
                                <div onClick={() => navigate(item.url as string)} className={cl.collection_item_long}>
                                    <img src={item.img} alt="" />
                                    <div className={cl.collection_info}>
                                        <div className={cl.collection_info_title}>{item.title}</div>
                                        <div className={cl.collection_info_gameCount}>Игр в подборке: {item.gameCount}</div>
                                    </div>
                                </div>
                                <div onClick={() => navigate(games[i + 1].url as string)} className={cl.collection_item}>
                                    <img src={games[i + 1].img} alt="" />
                                    <div className={cl.collection_info}>
                                        <div className={cl.collection_info_title}>{games[i + 1].title}</div>
                                        <div className={cl.collection_info_gameCount}>Игр в подборке:  {games[i + 1].gameCount}</div>
                                    </div>
                                </div>
                                </>
                            )}
                            </React.Fragment>
                        );
                        }
                        return null;
                    })}
                </div>
            </div>
        </div>
    )
}

export default AllCollectionsPage