import { useNavigate, useParams } from "react-router-dom"
import cl from './CollectionPage.module.scss'
import { useEffect, useState } from "react"
import axios from "axios"
import { ICollection} from "../../types/types"
import Seo from "../../Components/Seo"

const CollectionPage = () => {
    const navigate = useNavigate()
    const {url} = useParams()
    const [collection,setCollection] = useState<null | ICollection>(null)


    const getData = async () => {
        try {
            // const response = await axios.post('http://localhost:8080/collection/getCollection', { url: url });
            const response = await axios.post('https://api.steam-rent.com/collection/getCollection', { url: url });
            const collection = response.data.collection;
            setCollection(collection);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    useEffect(() => {
        window.scrollTo({top:0})
        getData()
    },[])

    if(!collection){
        return        <div onClick={() => navigate(-1)} className={cl.collection_page}></div>
    }
    return(
      <>
      <Seo description={collection.seoTitle} title={collection.seoTitle} />
      <div className={cl.cover}> <img src="https://icegames.store/img/selectionsBackground.png" /></div>
        <div onClick={() => navigate(-1)} className={cl.collection_page}>
            <div onClick={e => e.stopPropagation()} className={cl.content_container}>
                <h1>{collection.title}</h1>
                <p>{collection.description}</p>

                <div className={cl.products_container}>
                        <div className={cl.games_count}>Игры из подборки: <span> {collection.games.length}</span></div>
                        <div className={cl.products_list}>
                            {collection.games.map(item => 
                                <div onClick={() => navigate(`/shop/${item.url}`)} key={item.url} className={cl.collection_item}>
                                    <img src={item.img} />
                                    <div className={cl.info}>
                                     {item.title}
                                     
                                    </div>
                                </div>
                            )}
                        </div>
                </div>
            </div>
        </div>
      </>
    )
}


export default CollectionPage