import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Other from "../../store/Other";
import Product from "../../store/Product";
import RegUser from "../../store/RegUser";
import { IPaymentMethod } from "../../types/types";
import PaymentMethod from "./PaymentMethod";
import "./PaymentPage.scss";

const PaymentPage = () => {
  const navigate = useNavigate();
  const { userData } = RegUser;
  const { currency, initialC, openSideBar, isAuth } = Other;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { buyWithCashback, selectedOption, isCashbackUsed, currentGame } =
    Product;

  const [selectedMethod, setSelectedMethod] = useState<null | {
    methodName: string;
    paymentSystem: string;
  }>(null);

  const pay = () => {
    console.log(query.get('rent'))
    if (selectedMethod?.paymentSystem === "p2p") {
      navigate("/p2p");
    }
    buyWithCashback(
      currency,
      initialC,
      userData?.id,
      selectedMethod?.paymentSystem as string,
      selectedMethod?.methodName as string ,
      query.get('rent') ? true : false
    );
  };

  const [avilableMethods, setAvilableMethods] = useState<IPaymentMethod[]>([
    {
      id: 5,
      title: "Криптовалюта",
      imgFromFront: require("./../../assets/paymentIcons/bitcoin.png"),
      paymentSystem: "dgs",
    },
  ]);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_ServerLink}/getAvailablePaymentMethods`, {
        // .post("https://api.steam-rent.com/getAvailablePaymentMethods", {
        currency,
        amount: selectedOption.price,
        amountOfCashback: isCashbackUsed.amount,
      })
      .then((resp) => setAvilableMethods(resp.data.avilableMethods));
  }, [currency]);

  if (currentGame === null) {
    // navigate(-1);
    navigate("/shop");
  }
  return (
    <div className="payment-page">
      <h3> Выберите способ оплаты</h3>
      <div className="payment-page__container">
        <div className="payment-page__container-list">
          {avilableMethods.map((method: any) => (
            <PaymentMethod
              key={method.id}
              title={method.title}
              img={method.img}
              imgFromFront={method.imgFromFront}
              isSelected={selectedMethod?.methodName == method.title}
              onClick={() =>
                setSelectedMethod({
                  methodName: method.title,
                  paymentSystem: method.paymentSystem,
                })
              }
            />
          ))}
        </div>
        <div className="payment-page__container-inf">
          {/* <p className="payment-page__container-inf__nofif">
            Если вы не нашли подходящий метод оплаты,но вы можете{" "}
            <span>пополнить</span> свой баланси использовать его для дальнейших
            покупок
          </p> */}
          {initialC !== "UA" && (
            <p className="payment-page__container-inf__nofif">
              Для оплаты зарубежными картами сейчас есть минималки,но вы можете{" "}
              <span
                onClick={() =>
                  isAuth ? navigate("/userpage") : openSideBar(true)
                }
              >
                пополнить
              </span>{" "}
              свой баланс и использовать его для будущих покупок
            </p>
          )}
          <div
            style={currency == "₴" ? { display: "none" } : {}}
            className="payment-page__container-inf__min"
          >
            {(selectedOption.price < 97 && currency === "₽") ||
              (selectedOption.price < 1.2 && currency === "$" && (
                <p>Карта 1.20{currency}</p>
              ))}
            {selectedOption.price < 2.2 && currency === "$" && (
              <p>Карта Казахстан 2.20$</p>
            )}
            {/* {selectedOption.price < 4.5 && currency === "$" && (
              <p>Карта Беларусь 4.50$</p>
            )} */}
          </div>
        </div>
        <div
          onClick={() => selectedMethod && pay()}
          className="payment-page__container__btn"
        >
          ОПЛАТИТЬ
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
