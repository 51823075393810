import { makeAutoObservable } from "mobx";
import { IGame, IGameAccount, IGameShort } from "../types/types";
import axios from "axios";

class Shop {
  allGames: IGame[] | null = null;
  gameAccounts: null | IGameAccount[] = null;
  searchQuery = "";
  ////-----------filters------////
  publisher: string[] = [];
  platform: string[] = [];
  genre: string[] = [];
  isOnline: string[] = [];
  reset: boolean = false;
  language: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSearchQuery = (text: string) => {
    this.searchQuery = text;
  };

  resetFilters = () => {
    this.genre = [];
    this.platform = [];
    this.publisher = [];
    this.isOnline = [];
    this.language = [];
    this.reset = true;
    setTimeout(() => {
      this.reset = false;
    }, 300);
  };

  addLanguage = (arg: string) => {
    this.language = [...this.language, arg];
  };
  removeLanguage = (arg: string) => {
    this.language = this.language.filter((item) => item !== arg);
  };
  addGenre = (arg: string) => {
    this.genre = [...this.genre, arg];
  };
  removeGenre = (arg: string) => {
    this.genre = this.genre.filter((item) => item !== arg);
  };

  addPublisher = (arg: string) => {
    this.publisher = [...this.publisher, arg];
  };
  removePublisher = (arg: string) => {
    this.publisher = this.publisher.filter((item) => item !== arg);
  };

  addPlatform = (arg: string) => {
    this.platform = [...this.platform, arg];
  };

  removePlatform = (arg: string) => {
    this.platform = this.platform.filter((item) => item !== arg);
  };

  addType = (arg: string) => {
    this.isOnline = [...this.isOnline, arg];
  };
  removeType = (arg: string) => {
    this.isOnline = this.isOnline.filter((item) => item !== arg);
  };

  fetchGames = async () => {
    console.log("fetchGames");
    try {
      this.allGames = await axios
        // .get("https://api.steam-rent.com/api/games")
        .get(`${process.env.REACT_APP_ServerLink}/api/games`)
        .then((resp) => resp.data);
    } catch (e) {
      console.log(`Error ${e}`);
    }
  };
  
  fetchAccounts = async () => {
    try {
      if (this.gameAccounts === null) {
        this.gameAccounts = await axios
          // .get("https://api.steam-rent.com/api/games")
          .get("https://api.steam-rent.com/getGameAccounts")
          .then((resp) => resp.data.gameAccounts);
      }
    } catch (e) {
      console.log(e);
    }
  };

  get sortedGames() {
    let allGm = this.allGames;
    if (allGm == null) {
      return;
    }
    if (this.genre.length > 0) {
      allGm = allGm.filter((item) =>
        item.genre?.some((el) => this.genre.includes(el))
      );
    }
    if (this.platform.length > 0) {
      allGm = allGm.filter((item) =>
        this.platform?.includes(
          item.platform === "EGS" ? "Epic Games" : (item.platform as string)
        )
      );
    }
    if (this.publisher.length > 0) {
      allGm = allGm.filter((item) =>
        this.publisher?.includes(item.publisher as string)
      );
    }

    if (this.isOnline.length > 0) {
      allGm = allGm.filter(
        (item) =>
          this.isOnline?.includes(item.isOnline ? "Online" : "Offline") ||
          (item.online?.accounts.length as number) > 0
      );
    }

    if (this.language.length > 0) {
      allGm = allGm.filter((item) =>
        this.language?.includes(item.language as string)
      );
    }

    return allGm.filter(
      (game) =>
        game.title.toUpperCase().includes(this.searchQuery.toUpperCase()) 
      // &&game.visibility != false
    );
  }

  get sortedGameAccounts() {
    const x = this.gameAccounts?.filter((account) => {
      return account.title
        .toUpperCase()
        .includes(this.searchQuery.toUpperCase());
    });
    return x;
  }
}

export default new Shop();
