import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import GameAccountItem from "../../Components/GameAccountItem/GameAccountItem";
import GameAccountItemMobile from "../../Components/GameAccountItem/GameAccountItemMobile";
import List from "../../Components/List";
import Seo from "../../Components/Seo";
import GuardLoader from "../../Components/UI/GuardLoader";
import Loading100x100 from "../../Components/UI/Loading100x100";
import Search from "../../Components/UI/Search";
import Shop from "../../store/Shop";
import MatchMediaWrapper from "../../Utils/MatchMediaWrapper";
import cl from "./accountsShopPage.module.scss";

const AccountsShopPage = observer(() => {
  const { fetchAccounts, gameAccounts, sortedGameAccounts } = Shop;

  useEffect(() => {
    fetchAccounts();
  }, []);

  if (gameAccounts === null) {
    return <Loading100x100 />;
  }
  return (
    <div className={cl.accountsshoppage}>
      <Seo
        title="Купить аккаунты с играмии Steam ,Epic Games , Uplay"
        description="Наш интернет магазин специализируется на продаже и аренде аккаунтов с играми для популярных игровых платформ,таких как Стим ,Эпик Геймс , EA и Uplay."
      />
      <div className={cl.container}>
        <div className={cl.header}>
          <Search />
        </div>

        <div className={cl["list-wrapper"]}>
          {sortedGameAccounts !== undefined &&
            sortedGameAccounts.map((item: any, i: number) => (
              <MatchMediaWrapper
                desktopContent={<GameAccountItem item={item} custom={i} />}
                key={item._id}
                mobileContent={<GameAccountItemMobile item={item} custom={i} />}
              />
            ))}
          {/* <List arr={accounts} Top={GameAccountItem} /> */}
        </div>
      </div>
    </div>
  );
});

export default AccountsShopPage;
