import { FC, memo, useRef, useState } from "react";

const GameImgSlider: FC<any> = ({ screenshots, video ,title}) => {
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const sliderRef = useRef<any>(null);
  const imgRef = useRef<any>(null);

  const scrollFn = (e: any) => {
    // sliderRef.current.scrollLeft = sliderRef.current.scrollLeft + 10;
  };

  const scrollSlider = (direction: string) => {
    if (direction === "right") {
      sliderRef.current.scrollLeft =
        sliderRef.current.scrollLeft + imgRef.current.offsetWidth;
    } else {
      sliderRef.current.scrollLeft =
        sliderRef.current.scrollLeft - imgRef.current.offsetWidth;
    }
  };

  return (
    <div className="game-slider-wrapper">
      <div className="psgame-slider-wrapper__header">
        <div style={{ display: "flex" }}>
          <div
            onClick={() => setSelectedOption(1)}
            id={
              selectedOption == 1
                ? "psgame-slider-wrapper__header__btn_selected"
                : ""
            }
            className="psgame-slider-wrapper__header__btn"
          >
            СКРИНШОТЫ
          </div>
          <div
            onClick={() => {
              setSelectedOption(2);
            }}
            className="psgame-slider-wrapper__header__btn"
            id={
              selectedOption == 2
                ? "psgame-slider-wrapper__header__btn_selected"
                : ""
            }
          >
            ТРЕЙЛЕР
          </div>
        </div>
        {selectedOption == 1 && (
          <div className="psgame-slider-wrapper__header__scroll-btns">
            <div
              className="header__scroll-btn"
              onClick={() => scrollSlider("left")}
            >
              <img className="left" src={require("./leftIcon.png")} alt="" />
            </div>
            <div
              onClick={() => scrollSlider("right")}
              className="header__scroll-btn"
            >
              <img src={require("./leftIcon.png")} alt="" />
            </div>
          </div>
        )}
      </div>
      {selectedOption == 1 && (
        <div
          onMouseMove={(e) => scrollFn(e)}
          className="psgame-slider-wrapper__row"
          ref={sliderRef}
        >
          {screenshots &&
            screenshots.map((el: string, i: number) => (
              <div key={i} id="row__img" ref={imgRef} className="psgame-slider-wrapper__row__img">
                {el && <img alt={`${i} геймплей игры ${title} `} draggable="false" src={el} />}
              </div>
            ))}
        </div>
      )}

      {selectedOption == 2 && (
        <div className="psgame-slider-wrapper__row__video">
          <iframe src={video} allowFullScreen={true} frameBorder="0">
            {" "}
          </iframe>
        </div>
      )}
    </div>
  );
};
export default memo(GameImgSlider);


