import axios from "axios";
import { useEffect, useState } from "react";
import GuardLoader from "../Components/UI/GuardLoader";
import { useNavigate } from "react-router-dom";
import Other from "../store/Other";
//

//
const GuardsPage = () => {
  const { setActivePage } = Other;
  const [userEmail, setUserEmail] = useState("");
  const [guard, setGuard] = useState("");
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [copyAnim, setCopyAnim] = useState(false);
  const [isMailValid, setIsMailValid] = useState(true);

  let mailRx = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

  const checkGuard = async () => {
    try {
      console.log("checking...");
      setGuard("");
      setErrorText("");
      if (!mailRx.test(userEmail)) {
        setIsMailValid(false);
        return;
      }
      setIsLoading(true);
      const resp = await axios
        .post("https://api.steam-rent.com/getGuardForUser", {
          userEmail: userEmail.toLowerCase(),
        })
        // .post("http://localhost:8080/getGuardForUser", {
        //   userEmail: userEmail.toLowerCase(),
        // })
        .then((resp) => resp.data);
      console.log({ resp });
      if (resp.status == 200) {
        setGuard(resp.guard);
        if (!resp.isOnline) {
          resp.platform === "EpicGames"
            ? setErrorText("offline game egs")
            : setErrorText("offline game");
        }
      } else {
        setErrorText(resp.status);
      }
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    setActivePage(10);
    axios
      .get("https://api.steam-rent.com/checkmail")
      .then((resp) => console.log(resp.data));
  }, []);

  return (
    <div onClick={() => navigate("/shop")} className="guards-page">
      <div onClick={(e) => e.stopPropagation()} className="container">
        <h3>Steam Guard </h3>
        <div className="input-wrapper">
          <input
            placeholder="Email"
            value={userEmail}
            className="guard-input"
            onChange={(e) => setUserEmail(e.target.value)}
            type="email"
            id={isMailValid ? "" : "wrongMail"}
            onAnimationEnd={() => setIsMailValid(true)}
          />
          <img
            className="send-btn"
            onClick={() => checkGuard()}
            // src="https://img.icons8.com/nolan/64/sent.png"
            src={require("../assets/img/white-send2.png")}
            alt=""
          />
          {localStorage.getItem("userEmail") &&
            userEmail !== localStorage.getItem("userEmail") && (
              <div
                onClick={() =>
                  setUserEmail(localStorage.getItem("userEmail") as string)
                }
                className="email-tip"
              >
                {localStorage.getItem("userEmail")}
              </div>
            )}
        </div>
        <div className="guard-wrapper">
          {isLoading ? (
            <GuardLoader />
          ) : guard !== "" ? (
            <span
              onClick={() => {
                setCopyAnim(true);
                navigator.clipboard.writeText(guard);
              }}
              onAnimationEnd={() => setCopyAnim(false)}
              id={copyAnim ? "copy" : ""}
            >
              {guard}
            </span>
          ) : (
            <div style={errorText == "" ? { display: "none" } : {}}>
              <strong>{errorText}</strong>
              <p>Напишите в чат для ручной проверки</p>
            </div>
          )}
        </div>
        <div
          style={guard ? { display: "none" } : {}}
          className="guard-page__instruction"
        >
          <li>
            <strong>1</strong>Зайдите в аккаунт при помощи данных отправленных
            на вашу почту
          </li>
          <li>
            <strong>2</strong> Введите почту на которую была куплена игра
          </li>
          <li>
            <strong>3</strong> Получите код )
          </li>
          <p style={{ textAlign: "right", fontSize: "0.9em" }}>
            Эта страница работает в тестовом режиме*
          </p>
        </div>
        <div
          style={errorText != "offline game" ? { display: "none" } : {}}
          className="reminder"
        >
          <p>
            Не забудьте перейти в <span>автономный режим</span>, так как данная
            игра являться offline игрой на нашем сайте.
          </p>
          <p>
            Это можно сделать нажав в левом верхнем углу *Steam - перейти в
            автономный режим* чтобы избежать проблем с сохранением,однако для
            первого запуска вам потребуется быть в сети
          </p>
        </div>
        <div
          style={errorText != "offline game egs" ? { display: "none" } : {}}
          className="reminder"
        >
          <p>
            Не забудьте перейти в <span>автономный режим</span>, так как данная
            игра являться offline игрой на нашем сайте.
          </p>
          <p>
            Это можно сделать нажав на значок профиля в правом верхнем углу,
            «Настройки» и в разделе «Предпочтения» установите флажок напротив
            «Включить просмотр в автономном режиме».
          </p>
        </div>
      </div>
    </div>
  );
};

export default GuardsPage;
