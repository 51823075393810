import { FC } from "react";

const MobileImgSlider = ({ img, dlc }: any) => {
  console.log(img);
  return (
    <div className="card-slider__moblie">
      {img &&
        img.map((el: any) => (
          <div style={{ backgroundImage: `url(${el})` }} className="slide">
            {/* <img src={el} alt="" /> */}
          </div>
        ))}
      {dlc &&
        dlc.map((el: any) => (
          <div
            style={{ backgroundImage: `url(${el.screenshot})` }}
            className="slide"
          >
            {/* <img src={el} alt="" /> */}
          </div>
        ))}
    </div>
  );
};

export default MobileImgSlider;
