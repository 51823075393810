import { lazy, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// import MainPage from "../Pages/MainPage";
// import NewsPage from "../Pages/NewsPage";
// import ShopPage from "../Pages/ShopPage";
// import ReviewsPage from "../Pages/ReviewsPage";
// import Article1 from "../Pages/Articles/Atricle1";
// import Article2 from "../Pages/Articles/Article2";
// import ArticleNew3 from "../Pages/Articles/ArticleNew1";
// import ArticleNew from "../Pages/Articles/ArticleNew";
// import ArticleNew4 from "../Pages/Articles/ArticleNew4";
// import ArticleNew5 from "../Pages/Articles/AnticleNew5";
// import ArticleNew8 from "../Pages/Articles/ArticleNew8";
// import ArticleNew11 from "../Pages/Articles/ArticleNew11";
// import ArticleNew12 from "../Pages/Articles/ArticleNew12";
// import ArticleNew13 from "../Pages/Articles/ArticleNew13";
import { IGame } from "../types/types";
import GamePage from "../Pages/GamePage";
import GuardsPage from "../Pages/GuardsPage";
import ContractPage from "../Pages/ContractPage";
import SuccessPage from "../Pages/SuccessPage";
import PaymentFailPage from "../Pages/PaymentFailPage";
// import ArticleNew101 from "../Pages/Articles/ArticleNew101";
// import ArticleNew102 from "../Pages/Articles/ArticleNew102";
// import ArticleNew103 from "../Pages/Articles/ArticleNew103";
import GamePageRent from "../Pages/GamePageRent";
import AccountsShopPage from "../Pages/AccountsShop/AccountsShopPage";
import AccountGamePage from "../Pages/AccountGamePage/AccountGamePage";
import Instruction from "../Pages/Instruction/Instruction";
import UserPage from "../Pages/UserPage/UserPage";
import PaymentPage from "../Pages/PaymentPage/PaymentPage";
// import ArticleNew105 from "../Pages/Articles/ArticleNew105";
import P2PPage from "../Pages/P2P/p2pPage";
import GamePageN from "../Pages/GamePage/GamePageN";
import PSGameRentPage from "../Pages/PSGameRentPage/PSGameRentPage";
import ShopPs from "../Pages/ShopPS/ShopPS";
import UsersFavorteGames from "../Pages/UserPage/UsersFavoriteGames";
import PaymentPage2 from "../Pages/PaymentPage/PaymentPage2";
// import ArticleNew107 from "../Pages/Articles/ArticleNew107";
// import ArticleNew108 from "../Pages/Articles/ArticleNew108";
import GamePageNRent from "../Pages/GamePage/GamePageNRent";
import AllCollectionsPage from "../Pages/CollectionsPages/AllCollectionsPage";
import CollectionPage from "../Pages/CollectionsPages/CollectionPage";
const NewsPage = lazy(() => import("../Pages/NewsPage"));
const ShopPage = lazy(() => import("../Pages/ShopPage"));
const MainPage = lazy(() => import("../Pages/MainPage/MainPage"));
const ReviewsPage = lazy(() => import("../Pages/ReviewsPage"));

const Article1 = lazy(() => import("../Pages/Articles/ArticleNew1"));
const Article2 = lazy(() => import("../Pages/Articles/Article2"));
const ArticleNew3 = lazy(() => import("../Pages/Articles/ArticleNew1"));
const ArticleNew = lazy(() => import("../Pages/Articles/ArticleNew"));
const ArticleNew4 = lazy(() => import("../Pages/Articles/ArticleNew4"));
const ArticleNew5 = lazy(() => import("../Pages/Articles/AnticleNew5"));
const ArticleNew8 = lazy(() => import("../Pages/Articles/ArticleNew8"));
const ArticleNew11 = lazy(() => import("../Pages/Articles/ArticleNew11"));
const ArticleNew12 = lazy(() => import("../Pages/Articles/ArticleNew12"));
const ArticleNew13 = lazy(() => import("../Pages/Articles/ArticleNew13"));
const ArticleNew101 = lazy(() => import("../Pages/Articles/ArticleNew101"));
const ArticleNew102 = lazy(() => import("../Pages/Articles/ArticleNew102"));
const ArticleNew103 = lazy(() => import("../Pages/Articles/ArticleNew103"));
const ArticleNew105 = lazy(() => import("../Pages/Articles/ArticleNew105"));
const ArticleNew107 = lazy(() => import("../Pages/Articles/ArticleNew107"));
const ArticleNew108 = lazy(() => import("../Pages/Articles/ArticleNew108"));
const ArticleNew110 = lazy(() => import("../Pages/Articles/ArticleNew110"));



const AnimatedRouts = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="*" element={<div>404</div>} />
        <Route
          path="/"
          element={
            <Suspense fallback={<div className="nothing"></div>}>
              <MainPage />
            </Suspense>
          }
        />
        <Route
          path="/reviews"
          element={
            <Suspense fallback={<div className="nothing"></div>}>
              <ReviewsPage />
            </Suspense>
          }
        />
        {/* <Route path="/news" element={<NewsPage />} /> */}
        <Route
          path="/news"
          element={
            <Suspense fallback={<div className="nothing"></div>}>
              <NewsPage />
            </Suspense>
          }
        />
        {/* <Route path="/shop" element={<ShopPage />} /> */}
        <Route
          path="/shop"
          element={
            <Suspense fallback={<div className="nothing"></div>}>
              <ShopPage />
            </Suspense>
          }
        />
        <Route path="/gameaccounts" element={<AccountsShopPage />} />
        <Route path="/gameaccounts/:gameId" element={<AccountGamePage />} />
        {/* <Route path="/shop/:gameId" element={<GamePage />} /> */}
        <Route path="/shop/rent/:gameId" element={<GamePageNRent />} />
        /////
        <Route path="/guards" element={<GuardsPage />} />
        <Route path="/contract" element={<ContractPage />} />
        <Route path="/successpayment" element={<SuccessPage />} />
        <Route path="/failpayment" element={<PaymentFailPage />} />
        <Route
          path="/payment"
          element={
            <Suspense fallback={<div className="nothing"></div>}>
              <PaymentPage />
            </Suspense>
          }
        />
        <Route
          path="/payment2"
          element={
            <Suspense fallback={<div className="nothing"></div>}>
              <PaymentPage2 />
            </Suspense>
          }
        />
        <Route
          path="/instruction"
          element={
            <Suspense fallback={<div className="nothing"></div>}>
              <Instruction />
            </Suspense>
          }
        />
        <Route
          path="/userpage"
          element={
            <Suspense fallback={<div className="nothing"></div>}>
              <UserPage />
            </Suspense>
          }
        />
        <Route
          path="/userpage/favorites"
          element={
            <Suspense fallback={<div className="nothing"></div>}>
              <UsersFavorteGames />
            </Suspense>
          }
        />
        <Route
          path="/shop/ps"
          element={
            <Suspense fallback={<div className="nothing"></div>}>
              <ShopPs />
            </Suspense>
          }
        />
        <Route 
          path="/collections" element={
            <Suspense fallback={<div className="nothing"></div>}>
              <AllCollectionsPage/>
            </Suspense>
          }/>
        <Route 
          path="/collections/:url" element={
          <Suspense fallback={<div className="nothing"></div>}>
            <CollectionPage/>
          </Suspense>
          }/>
        <Route path="/shop/:gameId" element={<GamePageN/>} />
        {/* <Route path="/shop/:gameId" element={<GamePage />} /> */}
        <Route path="/shop/ps/:gameId" element={<PSGameRentPage />} />
        <Route path="/p2p" element={<P2PPage />} />
        /////
        <Route path="/news1" element={<Suspense> <Article1 /></Suspense>} />
        <Route path="/news2" element={ <Suspense> <Article2 /> </Suspense>} />
        <Route path="/news4" element={<Suspense>  <ArticleNew3 /> </Suspense>} />
        <Route path="/news5" element={<Suspense>  <ArticleNew /></Suspense>} />
        <Route path="/news333" element={ <Suspense> <ArticleNew4 /> </Suspense>} />
        <Route path="/news8"   element={<Suspense> <ArticleNew5 /> </Suspense>} />
        <Route path="/news88"  element={<Suspense> <ArticleNew8 /></Suspense>} />
        <Route path="/news11"  element={<Suspense> <ArticleNew11 /> </Suspense>} />
        <Route path="/news12"  element={<Suspense> <ArticleNew12 /></Suspense>} />
        <Route path="/news13"  element={<Suspense> <ArticleNew13 /></Suspense>} />
        <Route path="/news101" element={<Suspense> <ArticleNew101 /></Suspense>} />
        <Route path="/news102" element={<Suspense> <ArticleNew102 /></Suspense>} />
        <Route path="/news103" element={<Suspense> <ArticleNew103 /></Suspense>} />
        <Route path="/news105" element={<Suspense> <ArticleNew105 /></Suspense>} />
        <Route path="/news107" element={<Suspense> <ArticleNew107 /></Suspense>} />
        <Route path="/news108" element={<Suspense> <ArticleNew108 /></Suspense>} />
        <Route path="/news110" element={<Suspense> <ArticleNew110 /></Suspense>} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRouts;
