import { observer } from "mobx-react-lite";
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Other from "../../../store/Other";
import Product from "../../../store/Product";
import RegUser from "../../../store/RegUser";
import CryptoJS from "crypto-js";
import "./PrepaymentForm.scss";

const PrepaymentForm = observer(({ setDispay,isOnlineVersion }: any) => {
  const navigate = useNavigate();
  const { userData } = RegUser;
  const { currency, convertedMoney, initialC } = Other;
  const {
    selectedOption,
    currentGame,
    userEmail,
    setUserEmail,
    buyWithCashback,
    setIsCashbackUsed,
    isCashbackUsed,
    payUsingBalance,
    isGameAvailable
  } = Product;

  const [currentPrice, setCurrentPrice] = useState(selectedOption.price);
  const [emailSpalling, setEmailSpalling] = useState(true);

  const useCashback = (amountCshb: number) => {
    if (amountCshb >= selectedOption.price) {
      setCurrentPrice(
        Number((selectedOption.price - selectedOption.price).toFixed(2))
      );
      setIsCashbackUsed({ status: true, amount: selectedOption.price });
    } else {
      setIsCashbackUsed({ status: true, amount: amountCshb });

      setCurrentPrice(Number((selectedOption.price - amountCshb).toFixed(2)));
    }
  };

  var re = /\S+@\S+\.\S+/;
  const pay = async () => {
    if(!await isGameAvailable()){
      alert('аккаунт пока занят ¯\ _(ツ)_/¯ ')
      return
    }
    if (re.test(userEmail) && userEmail !== '') {
      setEmailSpalling(true);
    }
    if (re.test(userEmail) && currentGame?.free) {

      buyWithCashback(currency, initialC, "", "", "string",false);
      setDispay(false)
      return
    }
    
    if (isCashbackUsed && isCashbackUsed.amount == selectedOption.price) {
      ///encrypt
      const encryptedData = CryptoJS.AES.encrypt(
        String(userData?.id),
        "hui"
      ).toString();

      if (await payUsingBalance({ currency, encryptedData })) {
        navigate("/successpayment");
      }
      return;
    }

    if (userEmail === "pasha.lou2004@gmail.com") {
      setEmailSpalling(false);
      return;
    }
    if (re.test(userEmail)) {
      setDispay(false);
      setEmailSpalling(true);
      navigate(`/payment/${isOnlineVersion ? '?rent=true':''}`);
      return;
    }
    setEmailSpalling(false);
  };

  useEffect(() => {
    setCurrentPrice(selectedOption.price);
    setIsCashbackUsed({ status: false, amount: 0 });
  }, [currency]);
  return (
    <div
      onMouseDown={(e) => {
        e.stopPropagation();
        setDispay(false);
      }}
      className="prepayment-form"
    >
      <div
        onMouseDown={(e) => e.stopPropagation()}
        className="prepayment-form__container"
      >
        <div className="prepayment-form__title">{currentGame?.title}</div>
        <p>После оплаты, на эту почту вы получите данные для входа</p>
        <div className="input-wrapper">
          <input
            placeholder="Email"
            type="text"
            className="prepayment-form__input"
            id={emailSpalling == true ? "" : "wrong-email-validadtion"}
            onAnimationEnd={() => setEmailSpalling(true)}
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
          {localStorage.getItem("userEmail") && userEmail === "" && (
            <div
              className="email-tip"
              id="tip"
              onClick={() =>
                setUserEmail(localStorage.getItem("userEmail") as string)
              }
            >
              {localStorage.getItem("userEmail")}
            </div>
          )}
        </div>

        <div className="prepayment-form__payment-inform">
          <div className="prepayment-form__payment-inform__cashback">
            {userData != null ? (
              <>
                <p>
                  {" "}
                  Ваш кэшбэк:
                  {!isCashbackUsed.status
                    ? convertedMoney(userData.cashback).toFixed(2)
                    : convertedMoney(userData.cashback) < selectedOption.price
                    ? "0.00"
                    : (
                        convertedMoney(userData.cashback) - selectedOption.price
                      ).toFixed(2)}
                  {currency}
                </p>
                {!isCashbackUsed.status && !currentGame?.free && (
                  <div
                    onClick={() => {
                      useCashback(convertedMoney(userData.cashback));
                      // setIsCashbackUsed({ status: true });
                    }}
                    className="useCashback-btn"
                  >
                    Применить
                  </div>
                )}
              </>
            ) : (
              <p> У вас нет кэшбэка, так как вы не зарегистрированы</p>
            )}
          </div>
          <p style={{ fontSize: "1.5em" }}>
            К оплате {currentGame?.free ? "0" : currentPrice}
            {currency}
          </p>
        </div>
        <div onClick={() => pay()} className="prepayment-form__pay-btn">
          ОПЛАТИТЬ
        </div>
      </div>
    </div>
  );
});



export default memo(PrepaymentForm);
