import axios from "axios";
import { useEffect } from "react";

const UsersFavortesGames = () => {
  useEffect(() => {
    axios.post(
      "https://api.steam-rent.com/log",
      "page under construction".toUpperCase()
    );
  }, []);
  return (
    <div className="user-favorite-page">
      <h2>СТРАНИЦА В РАЗРАБОТКЕ</h2>
      <div
        style={{
          backgroundImage:
            "url(https://i.pinimg.com/736x/3b/c0/fe/3bc0fea20baf0487783d3847eb47c563.jpg)",
        }}
        className="user-favorite-page_img"
      ></div>
    </div>
  );
};

export default UsersFavortesGames;
