import axios from "axios";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useMemo, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate, useParams } from "react-router-dom";
import Seo from "../../Components/Seo";
import Other from "../../store/Other";
import PSGame from "../../store/PSGame";
import { IPSGame } from "../../types/types";
import PSGameAbout from "./PSGameAbout";
import PSGameImgSlider from "./PSGameImgSlider";
import "./PSGameRentPage.scss";
import RentalDurationSelector from "../../Components/RentalDurationSelector/RentalDurationSelector";
import PrepaymentForm2 from "../../Components/PrePaymentForm/PrepaymentForm2";
import CartStore from "../../store/CartStore";



const PSGameRentPage: FC = observer(() => {
  const navigate = useNavigate();
  const {setProductData} = CartStore 
  const { currency, openSideBar, logForServer, isAuth } = Other;
  const { dgsPay ,setCurrentPsGame} = PSGame;

  const [isAvilable, setIsAvilable] = useState<boolean>(true);
  const [product, setProduct] = useState<null | IPSGame>(null);
  const [prepaymentBlockVisibility,setPrepaymentBlockVisibility] = useState<boolean>(false)

  const checkAvailability = async () => {
    console.log("checking");
    try {
      const productData = await axios
        .get(
          `https://api.digiseller.ru/api/products/${product?.dgsId}/data
      `
        )
        .then((resp) => resp.data);
      console.log({ avilable: productData.product.is_available });
      setIsAvilable(productData.product.is_available == 1 ? true : false);
    } catch (e) {
      console.log("errrrrr");
    }
  };

  useEffect(() => {
    if(product){
      checkAvailability()
      setProductData(product,currency,'PSGame')
      setCurrentPsGame(product)
    } 
  }, [product,currency]);

  const minPrice = useMemo(() => {
    if (!product) {
      return null;
    }
    // console.log(product);
    switch (currency) {
      case "$":
        return product.minPrice.USD;
      case "₴":
        return product.minPrice.UAH ? product.minPrice.UAH : "";
      default:
        return product.minPrice.RUB;
    }
  }, [currency, product]);

  const fetchData = async () => {
    let fullUrl = window.location.href.split("/");
    const url = fullUrl[fullUrl.length - 1];
    const game = await axios
      .post(`${process.env.REACT_APP_ServerLink}/ps/getByUrl`, { url })
      .then((resp) => resp.data);
    logForServer({ title: game.title, action: "PS RENT" });
    setProduct(game);
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const buy = () => {
    if(!product){
      return
    }

    if(product.type == 'our'){
      setPrepaymentBlockVisibility(true)
      return
    }

    if(isAvilable){
      currency !== "₴" && dgsPay(product.dgsId)
    }else{
      alert("Этот аккаунт сейчас занят(")
    }
                          
  }

  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <Seo
        title={`Аренда ${product?.title} для ${
          product?.platform.ps4 ? "PS4" : ""
        } ${product?.platform.ps5 ? "PS5" : ""} `}
        description={`SteamRent - Прокат аккаунтов с играми для PlayStation и PC. У нас вы уже можете арендовать аккаунт с игрой ${product?.title} для PS4 или PS5. Доставка аккаунта автоматическая, так что вы можете начать играть сразу после оплаты.`}
      />
      <div
        id={product ? "psrgame-page_anim" : ""}
        style={
          !product
            ? {}
            : {
                background: `
      linear-gradient(
        180deg,
        ${product.bcColor} 0%,
        rgb(8, 12, 25) 50%,
        rgba(0, 0, 0, 0) 100%
      )`,
              }
        }
        className="psrgame-page"
        onClick={() => navigate("/shop/ps")}
      >
        <div className="psrgame-page__wrapper">
          <div
            onClick={(e) => e.stopPropagation()}
            className="content-container"
          >
            <div className="content-container-wrp">
              <div className="content-container__left ">
                <div className="content-container__left__img ">
                  {product && <img src={product.img} />}
                  {product?.metaCritic && (
                    <div className="content-container__left__metacritic">
                      <span> {product.metaCritic}</span>
                      <p>Metacritic</p>
                    </div>
                  )}
                </div>
                <div className="content-container__left-details">
                  <div className="content-container__left-details__item">
                    <dt>Жанр</dt>
                    <dd>{product ? product.genre[0] : <Skeleton />}</dd>
                  </div>
                  <div className="content-container__left-details__item">
                    <dt>Платформа</dt>
                    <dd>
                      {product ? (
                        `${product.platform.ps4 ? "PS4" : ""} ${
                          product.platform.ps5 && "PS5"
                        }`
                      ) : (
                        <Skeleton />
                      )}
                    </dd>
                  </div>
                  <div className="content-container__left-details__item">
                    <dt>Дата выхода</dt>
                    <dd>{product ? product.releaseDate : <Skeleton />}</dd>
                  </div>
                  <div className="content-container__left-details__item">
                    <dt>Издатель</dt>
                    <dd>{product ? product.publisher : <Skeleton />}</dd>
                  </div>
                </div>
              </div>
              <div className="content-container__right">
                <div className="content-container__right-details">
                  <img
                    src={require("../../assets/img/emptyHeart.png")}
                    id="favorite"
                    onClick={() =>
                      isAuth
                        ? navigate("/userpage/favorites")
                        : openSideBar(true)
                    }
                  />
                  <div className="content-container__right-details__title">
                    <h1>
                      {product ? `${product.isOnline ?'Аренда':'Активация'} ${product.title}` : <Skeleton />}
                    </h1>{" "}
                  </div>

                  
                {/* {product?.isOnline ? <RentalDurationSelector/>:<div className="details-indent"></div>}  */}
                <div className="content-container__right-details__availability"></div>
                  <div className="content-container__right-details__price">
                    <button
                      onClick={() =>
                        buy()
                      }
                    >
                      КУПИТЬ
                    </button>

                    {product ? (
                      <span>
                        {product.isOnline && 'от'} {minPrice} {currency}
                      </span>
                    ) : (
                      <Skeleton />
                    )}
                  </div>

                  <div className="content-container__right-details__subifo">
                    <div className="content-container__right-details__subifo__item">
                      <p>Поддержка языков</p>
                      <span className="subinfo-type__answ">
                        {product ? product.language : <Skeleton />}
                      </span>
                    </div>
                    <div className="content-container__right-details__subifo__item">
                      <p className="subinfo-type">
                        Тип
                        <div className="subinfo-type_tip">
                          {product?.isOnline ?
                        <p>
                            Приобретая игру с данной меткой, покупатель будет
                            единственным пользователем аккаунта на весь
                            указанный срок
                          </p>
                          :
                          <p>Аккаунт общий ,поэтому после скачиваниые игры вам нужно будет отключить итернет на консоли</p>

                        }                         
                        </div>
                      </p>
                      <span className="subinfo-type__answ">
                        {product ? (
                          product.isOnline ? (
                            "Online"
                          ) : (
                            "Offline"
                          )
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </div>
                    <div className="content-container__right-details__subifo__item">
                      <p>Платформа</p>
                      <span className="subinfo-type__answ">
                        {product ? (
                          `
                          ${
                            product.platform.ps4 && product.platform.ps5
                              ? "PS4/PS5"
                              : `${product.platform.ps4 ? "PS4" : ""} ${
                                  product.platform.ps5 && "PS5"
                                }`
                          }
                          `
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <PSGameImgSlider
                  screenshots={product ? product.screenshots : [null, null]}
                  video={product ? product.video : null}
                />
              </div>
            </div>
            <PSGameAbout product={product} />
          </div>
        </div>
      </div>
      {!product?.isOnline && prepaymentBlockVisibility && 
    <PrepaymentForm2
      display={prepaymentBlockVisibility}
      setDispay={setPrepaymentBlockVisibility}
      product={product}
    />
      }
    </SkeletonTheme>
    
  );
});

export default PSGameRentPage;
