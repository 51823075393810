import axios from "axios";
import { makeAutoObservable } from "mobx";
import { IPSGame } from "../types/types";
import Other from "./Other";

class PSGame {
  PSGames: IPSGame[] | null = null;
  currentPSGame:IPSGame|null = null;

  constructor() {
    makeAutoObservable(this);
  }



  fetchPSGames = async () => {
    try {
      console.log("psF");
      const games = await axios
        .get(`${process.env.REACT_APP_ServerLink}/ps/getGames`)
        .then((resp) => resp.data);
      this.PSGames = games;
      return;
    } catch (e) {
      console.log("fetchGamesErrrrrr");
    }
  };

  dgsPay = (productId: number) => {
    window.location.href = `https://www.oplata.info/asp2/pay_wm.asp?id_d=${productId}&ai=1113898&lang=en`;
  };

  setCurrentPsGame = (currentPSGame:IPSGame) => {
    this.currentPSGame = currentPSGame
  }

  get sortedPSGames() {
    return  Other.currency == '₴' ?  this.PSGames?.filter(item => item.isOnline == false) : this.PSGames
  }
} 

export default new PSGame();
