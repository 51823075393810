import { useState } from "react";

const ImgWithPreloader = ({ src }: any) => {
  const [isLoaded, setIsloading] = useState(false);

  if (!src) {
    return <div></div>;
  }
  return (
    <div
      style={!isLoaded ? {} : { background: "transparant" }}
      className="preloaded-img__wrapper"
      key={src}
    >
      <img
        src={src}
        style={isLoaded ? { visibility: "visible" } : { visibility: "hidden" }}
        draggable={false}
        onLoad={() => setIsloading(true)}
      />
    </div>
  );
};

export default ImgWithPreloader;
