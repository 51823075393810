import { memo, useState } from "react";

const P2PCopy = ({ text }: any) => {
  const [anim, setAnim] = useState(false);
  return (
    <div
      onClick={() => {
        navigator.clipboard.writeText(text);
        setAnim(true);
      }}
      onAnimationEnd={() => setAnim(false)}
      className="payment-details__main__copy"
      id={anim ? "payment-details__main__copy_anim" : ""}
    >
      <img src={require("../../assets/icons/copy.png")} alt="" />
    </div>
  );
};

export default memo(P2PCopy);
