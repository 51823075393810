import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const ContractPage = () => {
  const navigate = useNavigate();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.37 } }}
      exit={{ opacity: 0, transition: { duration: 0.25 } }}
      onClick={() => navigate("/shop")}
      className="contract__page"
    >
      <div onClick={(e) => e.stopPropagation()} className="container">
        <h1>ДОГОВОР-ОФЕРТА</h1>
        <h3>Предмет договора</h3>
        <p>
          1.Предметом настоящего Договора является возмездное оказание
          Исполнителем услуг аренды в соответствии с условиями настоящего
          Договора.
        </p>
        <p>
          2.Заказчик полностью принимает условия Договора и оплачивает услуги
          Исполнителя в соответствии с условиями настоящего Договора.
        </p>
        <p>Способы оплаты услуги указаны при оформлении платежа.</p>

        <h3>Оплата</h3>
        <p>
          1. Стоимость услуг по Договору определяется в соответствии с
          действующими ценами и прописана на сайте https://steam-rent.com
        </p>
        <p>
          2. Стоимость услуги может быть изменена Исполнителем в одностороннем
          порядке.
        </p>

        <h3>Особые условия и ответственность сторон</h3>
        <p>
          1. Исполнитель несет ответственность за своевременность
          предоставляемых услуг при выполнении Заказчиком установленных
          требований и правил, размещенных на сайте https://steam-rent.com
        </p>
        <p>
          2. Исполнитель освобождается от ответственности за нарушение условий
          Договора, если такое нарушение вызвано действием обстоятельств
          непреодолимой силы (форс-мажор), включая: действия органов
          государственной власти, пожар, наводнение, землетрясение, другие
          стихийные действия, отсутствие электроэнергии, забастовки, гражданские
          волнения, беспорядки, любые иные обстоятельства, не ограничиваясь
          перечисленным, которые могут повлиять на выполнение Исполнителем
          Договора.
        </p>
        <p>
          3. Заказчик может не предоставлять повторный доступ, если IP адреса
          исполнителя не будут совпадать
        </p>
        <p>
          4. Исполнитель не несет ответственности за качество каналов связи
          общего пользования или служб, предоставляющих доступ Заказчика к его
          услугам.
        </p>

        <h3>Конфиденциальность и защита персональной информации</h3>

        <p>
          1. Исполнитель обязуется не разглашать полученную от Заказчика
          информацию, а также не передавать доступ 3-м лицам
        </p>
        <p>
          2. Не считается нарушением обязательств разглашение информации в
          соответствии с обоснованными и применимыми требованиями закона.
        </p>
        <p>
          3. Исполнитель получает информацию об IP-адресе посетителя сайта
          https://steam-rent.com/. Данная информация не используется для
          установления идентификации посетителя.
        </p>
        <p>
          4. Исполнитель не несет ответственности за сведения, предоставленные
          Заказчиком на сайте https://steam-rent.com/ в общедоступной форме.
        </p>
        <p>
          5. Страницы на этом сайте могут включать встраиваемое содержимое
          (например видео, изображения, статьи и др.), подобное содержимое ведет
          себя так же, как если бы посетитель зашел на другой сайт. Эти сайты
          могут собирать данные о вас, использовать куки, внедрять
          дополнительное отслеживание третьей стороной и следить за вашим
          взаимодействием с внедренным содержимым, включая отслеживание
          взаимодействия, если у вас есть учетная запись и вы авторизовались на
          том сайте.
        </p>

        <h3>Порядок рассмотрения претензий и споров</h3>
        <p>
          1. Претензии Заказчика по предоставляемым услугам принимаются
          Исполнителем к рассмотрению по электронной почте в течение 3 (рабочих)
          дней с момента обращения в службу поддержки .
        </p>

        <h3>Прочие условия</h3>
        <p>
          1. После оказания в надлежащем качестве Дополнительной Платной Услуги,
          деньги, потраченные на ее приобретение, возврату не подлежат. В
          случае, если услуга не была оказана в полной мере или была оказана в
          ненадлежащем качестве (баланс личного кабинета не был пополнен или
          сумма пополнения отличается от расчётной суммы при оплате заказа),
          заказчик имеет право отказаться от услуги, обратившись по электронной
          почте, и получить полную компенсацию ее стоимости либо аналогичную
          услугу на карту или кошелек. Срок рассмотрения претензий и возврата
          средств — 15 рабочих дней.
        </p>
        <p>
          2. Исполнитель оставляет за собой право изменять или дополнять любые
          из условий настоящего Договора в любое время, опубликовывая все
          изменения на своем сайте.
        </p>
      </div>
    </motion.div>
  );
};

export default ContractPage;
