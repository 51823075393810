import { FC, memo, useEffect, useMemo, useState } from 'react'
import Other from '../../../store/Other'
import { observer } from 'mobx-react-lite'
import Product from '../../../store/Product'
import '../../../Components/RentalDurationSelector/RentalDurationSelector.scss'


interface Props {
    pricee:any
    setMnPrice:(number:any) => void
    online:boolean
}

const RentalDurationSelect:FC <Props> = observer(({pricee,setMnPrice,online}) => {
    const {currency} = Other
    const { setSelectedOption, selectedOption, } = Product;
    const [optionsVisibility,setOptionsVisibility] = useState<boolean>(false)
    const [price,setPrice] = useState(pricee)

    const aviliblePrices: any[] = useMemo(() => {
        let rs = [];
        if(online){
          switch(currency){
            case '₴':
              for(let key in pricee.uah){
                console.log(key)
               switch(key){
                case 'day':
                  rs.push({ duration: 'uahPerDay', price: pricee.uah[key] });
                  break;
                  case 'week':
                    rs.push({ duration: 'uahPerWeek', price: pricee.uah[key] });
                    break
                    case 'month':
                      rs.push({ duration: 'uahPerMonth', price: price.uah[key] });
               }
              }
              break
            case '$':
              for(let key in price.uah){
                switch(key){
                 case 'day':
                   rs.push({ duration: 'usdPerDay', price: price.usd[key] });
                   break;
                   case 'week':
                     rs.push({ duration: 'usdPerWeek', price: price.usd[key] });
                     break
                     case 'month':
                       rs.push({ duration: 'usdPerMonth', price: price.usd[key] });
                }
               }
            
              break
            
              default:
                for(let key in price.uah){
                  switch(key){
                   case 'day':
                     rs.push({ duration: 'uahPerDay', price: price.r[key] });
                     break;
                     case 'week':
                       rs.push({ duration: 'uahPerWeek', price: price.r[key] });
                       break
                       case 'month':
                         rs.push({ duration: 'uahPerMonth', price: price.r[key] });
                  }
                 }

          }
          setMnPrice(rs[0].price)
          return rs
        }
        for (let key in price) {
          switch (currency) {
            case "$":
              if (price[key] && key[2] == "d") {
                rs.push({ duration: key, price: price[key] });
              }
              break;
            case "₴":
              if (price[key] && key[2] == "h") {
                rs.push({ duration: key, price: price[key] });
              }
              break;
            default:
              if (price[key] && key[2] == "b") {
                rs.push({ duration: key, price: price[key] });
              }
          }
        }
        setMnPrice(rs[0].price)
        return rs;
      }, [currency, price]);


      
  useEffect(() => {
    setSelectedOption({
      price: Number(aviliblePrices[0].price),
      duration:
        aviliblePrices[0].duration[6] == "D"
          ? "день"
          : aviliblePrices[0].duration[6] == "W"
          ? "неделя"
          : "месяц",
    });
  }, [currency, price]);

    



    return(
        <div className="RentalDurationSelector_container">
            <div 
            onClick={() => setOptionsVisibility(!optionsVisibility)}
            className="RentalDurationSelector">
                <span>1 {selectedOption.duration}</span>             <span>{selectedOption.price} {currency}</span>  
            </div>
            <div
                style={{display:optionsVisibility?'':'none'}}
                className="RentalDurationSelector-options-wrapper">
                {aviliblePrices.map(item => 
                    <div
                    key={item.price}
                    onClick={() =>
                       { setSelectedOption({
                          duration:
                            item.duration[6] == "D"
                              ? "день"
                              : item.duration[6] == "W"
                              ? "неделя"
                              : "месяц",
                          price: item.price,
                        });
                        setOptionsVisibility(false)}}
                      
                    className="RentalDurationSelector-options-wrapper__option">
                    <span>{item.duration[6] == "D"
                      ? "1 день"
                      : item.duration[6] == "W"
                      ? "1 неделя"
                      : "1 месяц"}{" "}</span>         
                    <span>{item.price} {currency}</span>
                    </div>
                )}
            </div>
        </div>
        
    )
})


export default memo(RentalDurationSelect)