import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import Other from "../store/Other";

const GameAccountPrice = observer(({ price }: any) => {
  const { currency } = Other;
  const currentPrice = useMemo(() => {
    switch (currency) {
      case "₴":
        return price.uah;
      case "$":
        return price.usd;

      default:
        return price.r;
    }
  }, [currency]);
  return (
    <>
      {currentPrice} {currency}
    </>
  );
});

export default GameAccountPrice;
