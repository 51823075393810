import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Other from "../../store/Other";
import cl from "./gameAccountItem.module.scss";

const GameAccountItemMobile = observer(({ item }: any) => {
  const navigate = useNavigate();
  const { currency } = Other;

  const currentPrice = useMemo(() => {
    switch (currency) {
      case "₴":
        return item.price.uah;
      case "$":
        return item.price.usd;

      default:
        return item.price.r;
    }
  }, [currency]);
  return (
    <div
      className="shop-item_mobile"
      style={{ backgroundImage: `url(${item.img})` }}
      onClick={() => navigate(`/gameaccounts/${item.id}`)}
    >
      <div className="shop-item_mobile__about">
        <div className="about__name">{item.title}</div>
        <div className={cl.about__price}>
          <p>{item.platform}</p>
          {currentPrice}
          {currency}
        </div>
      </div>
    </div>
  );
});

export default GameAccountItemMobile;
