import axios from "axios";

//----------------- AndrsLinks ----------------//

// export const paymentAmount = {
//   n249: "https://prt.mn/yoSXKZ6N9f",
//   n197: "https://prt.mn/jRkmXMG_bZ",
//   n149: "https://prt.mn/u7KcfdMiAJ",
//   n97: "https://prt.mn/ao6ZncgYj4",
//   n79: "https://prt.mn/zxZtmcbX8",
//   n9: "https://prt.mn/okavUOZyhq",
//   n19: "https://prt.mn/ttBPWLw8p",
//   n29: "https://prt.mn/3z42Er0NE-",
//   n49: "https://prt.mn/uJ5miNnREn",
//   n45: "https://prt.mn/eqhhOD5zT",
//   n34: "https://prt.mn/klIhS7tQSd",
//   n39: "https://prt.mn/BVnnRDvL3",
//   n32: "https://prt.mn/Yr_pkHNEns",
//   n30: "https://prt.mn/Ggybx4oKhO",
// };

// export const paymentAmount = {
//   n249: "https://prt.mn/yoSXKZ6N9f",
//   n197: "https://www.ipay.ua/ru/constructor/gpq0jlv7",
//   n149: "https://www.ipay.ua/ru/constructor/yv60dzda",
//   n97: "https://www.ipay.ua/ru/constructor/mjxkmhyi",
//   n79: "https://prt.mn/zxZtmcbX8",
//   n9: "https://prt.mn/okavUOZyhq",
//   n19: "https://prt.mn/ttBPWLw8p",
//   n29: "https://prt.mn/3z42Er0NE-",
//   n49: "https://www.ipay.ua/ru/constructor/qkWFON2j",
//   n45: "https://www.ipay.ua/ru/constructor/qkWFON2j",
//   n34: "https://prt.mn/klIhS7tQSd",
//   n39: "https://prt.mn/BVnnRDvL3",
//   n32: "https://prt.mn/Yr_pkHNEns",
//   n30: "https://prt.mn/Ggybx4oKhO",
// };

//----------------- MyLinks ----------------//

// export const paymentAmount = {
//   n249: "https://www.ipay.ua/ru/constructor/j53qbr4d",
//   n197: "https://www.ipay.ua/ru/constructor/tyiznbpw",
//   n149: "https://www.ipay.ua/ru/constructor/1w1skevk",
//   n97: "https://www.ipay.ua/ru/constructor/yfkriiev",
//   n79: "https://www.ipay.ua/ru/constructor/mdpk9exg",
//   n9: "https://prt.mn/okavUOZyhq",
//   n19: "https://prt.mn/ttBPWLw8p",
//   n29: "https://prt.mn/3z42Er0NE-",
//   n49: "https://www.ipay.ua/ru/constructor/5zfkoaol",
//   n45: "https://www.ipay.ua/ru/constructor/lgacffov",
//   n34: "https://prt.mn/klIhS7tQSd",
//   n39: "https://prt.mn/BVnnRDvL3",
//   n32: "https://prt.mn/Yr_pkHNEns",
//   n30: "https://prt.mn/Ggybx4oKhO",
// };

//portmone

export const paymentAmount = {
  n297: "https://www.portmone.com.ua/r3/perekaz-za-zapitom/78Pi486",
  n247: "https://www.portmone.com.ua/r3/perekaz-za-zapitom/786i9xc ",
  n197: "https://www.portmone.com.ua/r3/perekaz-za-zapitom/79citdt",
  n147: "https://www.portmone.com.ua/r3/perekaz-za-zapitom/7f8iPbq",
  n97: "https://www.portmone.com.ua/r3/perekaz-za-zapitom/7f8iP68",
  n77: " https://www.portmone.com.ua/r3/perekaz-za-zapitom/78diM5B",
  n9: "https://prt.mn/okavUOZyhq",
  n19: "https://prt.mn/ttBPWLw8p",
  n29: "https://prt.mn/3z42Er0NE-",
  n47: "https://www.portmone.com.ua/r3/perekaz-za-zapitom/7dDiHS5",
  n45: "https://www.ipay.ua/ru/constructor/lgacffov",
  n34: "https://prt.mn/klIhS7tQSd",
  n39: "https://prt.mn/BVnnRDvL3",
  n32: "https://prt.mn/Yr_pkHNEns",
  n30: "https://prt.mn/Ggybx4oKhO",
};

export const buyU = (amount: number, email: string, product?: any): void => {
  axios.post("https://api.steam-rent.com/log", { email, amount, cr: "uah" });
  axios.post("https://api.steam-rent.com/prePeyment", {
    email,
    amount,
    duration: product.duration,
    productId: product.productId,
    productTitle: product.title,
    isOnline: product.isOnline ? product.isOnline : false,
  });

  //   if (amount > 290) {
  //     window.open(paymentAmount.n297);
  //     return;
  //   }
  //   if (amount > 240) {
  //     window.open(paymentAmount.n247);
  //     return;
  //   }
  //   if (amount > 190) {
  //     window.open(paymentAmount.n197);
  //     return;
  //   }
  //   if (amount > 139) {
  //     window.open(paymentAmount.n147);
  //     return;
  //   }
  //   if (amount <= 99 && amount > 85) {
  //     window.open(paymentAmount.n97);
  //     return;
  //   }
  //   if (amount <= 85 && amount > 70) {
  //     window.open(paymentAmount.n77);
  //     return;
  //   }
  //   if (amount <= 59 && amount > 45) {
  //     window.open(paymentAmount.n47);
  //     return;
  //   }
  //   if (amount <= 47 && amount > 43) {
  //     window.open(paymentAmount.n45);
  //     return;
  //   }
  //   if (amount <= 35 && amount > 32) {
  //     window.open(paymentAmount.n34);
  //     return;
  //   }
  //   if (amount < 45 && amount > 35) {
  //     window.open(paymentAmount.n39);
  //     return;
  //   }
  //   if (amount < 33 && amount > 29) {
  //     window.open(paymentAmount.n30);
  //     return;
  //   }
  //   if (amount <= 30 && amount > 20) {
  //     window.open(paymentAmount.n29);

  //     return;
  //   }
  //   if (amount <= 20 && amount > 10) {
  //     window.open(paymentAmount.n19);

  //     return;
  //   }
  //   if (amount <= 10) {
  //     window.open(paymentAmount.n9);
  //     return;
  //   }
  //   console.log("worng price");
  // };

  axios
    .post("https://api.steam-rent.com/upay", { amount })
    .then((resp) => window.open(resp.data.currentLink));
};

export const buyDgs = (
  dgs: any,
  duration: string,
  _id: any,
  promo: boolean
) => {
  console.log({ dgs, duration, promo });
  if (dgs == null) {
    console.log("dgs null");
    return;
  }
  let id;
  let dr;
  switch (duration) {
    case "день":
      id = promo ? dgs.pd : dgs.d;
      dr = 1;
      break;
    case "неделя":
      id = promo ? dgs.pw : dgs.w;
      dr = 7;
      break;
    case "месяц":
      id = promo ? dgs.pm : dgs.m;
      dr = 30;
      break;
    default:
      console.log("defaul");
      return;
  }

  window.open(
    `https://www.digiseller.market/asp2/pay_wm.asp?id_d=${id}&lang=ru-RU&${_id}=${dr}`
  );
};

export const buyFk = (
  amount: number,
  currency: string,
  _id: number,
  duration: string
) => {
  axios
    // .post("http://localhost:8080/payfk", { amount, currency, _id, duration })
    .post("https://api.steam-rent.com/payfk", {
      amount,
      currency,
      _id,
      duration,
    })
    .then((resp) => window.open(resp.data.link));
};

export const buyPrimePayment = async (
  amount: number,
  currency: string,
  productId: number,
  duration: string,
  userEmail: string,
  productTitle: string,
  isOnline: any
) => {
  axios
    // .post("http://localhost:8080/payfk", { amount, currency, _id, duration })
    // .post("http://localhost:8080/primepaymentspay", {
    .post("https://api.steam-rent.com/primepaymentspay", {
      amount,
      transactionId: Date.now(),
      currency: currency,
      userEmail,
      comment: {
        productId,
        title: productTitle,
        duration: duration,
        isOnline,
      },
    })
    .then((resp) => window.open(resp.data.link));
};

export const buyDgsOnline = (dgs: any, duration: string, _id: number) => {
  let id;
  let dr;
  switch (duration) {
    case "день":
      id = dgs.d;
      dr = 1;
      break;
    case "неделя":
      id = dgs.w;
      dr = 7;
      break;
    case "месяц":
      id = dgs.m;
      dr = 30;
      break;
    default:
      console.log("defaul");
      return;
  }

  window.open(
    `https://www.digiseller.market/asp2/pay_wm.asp?id_d=${id}&lang=ru-RU&${_id}=${dr}&isOnline=true`
  );
};

export const buyGameAccountDgs = (dgsId: number, _id: string) => {
  window.open(
    `https://www.digiseller.market/asp2/pay_wm.asp?id_d=${dgsId}&lang=ru-RU&${_id}=${"forever"}&isOnline=true`
  );
};
