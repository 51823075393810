import React from "react";

const PaymentFailPage = () => {
  return (
    <div className="payment-fail__page">
      <div className="container">
        <h1>Платеж не прошел</h1>
        <h2>попробуйте еще раз</h2>
      </div>
    </div>
  );
};

export default PaymentFailPage;
