import axios from "axios";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GameAccountPrice from "../../Components/GameAccountPrice";
import MobileImgSlider from "../../Components/Product/MobileImgSlider";
import PrepaymentBlock from "../../Components/Product/PrepaymentBlock";
import SliderFrorGamePage from "../../Components/Product/SliderFrorGamePage";
import VideoYb from "../../Components/Product/VideoYb";
import Seo from "../../Components/Seo";
import GameAccounts from "../../store/GameAccounts";
import Other from "../../store/Other";
import { IGameAccount } from "../../types/types";
import MatchMediaWrapper from "../../Utils/MatchMediaWrapper";
import cl from "./AccountGamePage.module.scss";

const AccountGamePage = observer(() => {
  const navigate = useNavigate();
  const { gameId } = useParams();
  const { currency, logForServer } = Other;
  const { buy, currentAccount, setCurrentAccount } = GameAccounts;
  const [option, setOption] = useState<number>(1);
  const [whatAbout, setWhatAbout] = useState(1);
  const [animation, setAnimation] = useState<boolean>(false);
  const [prepaymentBlockVisibility, setPrepaymentBlockVisibility] =
    useState(false);

  useEffect(() => {
    setAnimation(true);
    return () => {
      setCurrentAccount(null);
    };
  }, []);

  if (currentAccount === null) {
    console.log("fets");
    axios
      .post("https://api.steam-rent.com/getGameAccountById", { gameId })
      .then((resp) => {
        setCurrentAccount(resp.data.game[0]);
        logForServer({ action: "BUY", title: resp.data.game[0].title });
      });
    return (
      <div
        id={`game-page__-anim`}
        className={cl["game-page"]}
        onMouseDown={() => navigate("/gameaccounts")}
        style={!animation ? {} : { backgroundPositionY: "-1000px" }}
      ></div>
    );
  }

  return (
    <>
      <div
        id={`game-page__${currentAccount.bcColor}-anim`}
        className={cl["game-page"]}
        style={animation ? {} : { backgroundPositionY: "-1000px" }}
        onMouseDown={() => navigate("/gameaccounts")}
      >
        <Seo
          title={`Купить аккаунт с игрой ${currentAccount.title} ${currentAccount.platform}`}
          description=""
        />
        <div
          className={cl["content-container"]}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <div className={cl["card-row"]}>
            <div
              style={{ backgroundImage: `url(${currentAccount.img})` }}
              className={cl["card-row__img"]}
            >
              <div
                style={currentAccount.metaRating ? {} : { display: "none" }}
                className={cl["game-rating"]}
                onClick={() => window.open("https://www.metacritic.com/game")}
              >
                <span>{currentAccount.metaRating}</span>
                <p>Metacritic</p>
              </div>
            </div>
            <div className={cl["card-row__col"]}>
              <h1 className={cl["game__title"]}>{currentAccount.title}</h1>
              <h3 className={cl["accaunt-type"]}>Личный аккаунт и почта</h3>

              <div className={cl["buy-row"]}>
                <div className={cl["card__buy"]}>
                  <div
                    className={cl["buy__btn"]}
                    onClick={() => {
                      setPrepaymentBlockVisibility(true);
                    }}
                  >
                    КУПИТЬ
                  </div>
                  <span className={cl["game-price"]}>
                    <GameAccountPrice price={currentAccount.price} />
                  </span>
                  {/* <PromoInput /> */}
                </div>
              </div>

              <div className={cl["card-row__subinfo"]}>
                <div className={cl["card-row__subinfo-item"]}>
                  <p>Поддержка языков</p>
                  <span>{currentAccount.language}</span>
                </div>
                <div className={cl["card-row__subinfo-item"]}>
                  <p>Сервис активации</p>
                  <span>
                    {currentAccount.platform === "EGS"
                      ? "Epic Games"
                      : currentAccount.platform}
                  </span>
                </div>
                <div className={cl["card-row__subinfo-item"]}>
                  <p>Тип</p>
                  <span>Аккаунт</span>
                </div>
              </div>
            </div>
          </div>
          <div className={cl["card-row-min"]}>
            <div className={cl["card-row__dopinfo"]}>
              <div className={cl["card-row__dopinfo-item"]}>
                <p>Жанр</p>

                <span>
                  {currentAccount.genre
                    ? `${currentAccount.genre[0]}, ${
                        currentAccount.genre[2]
                          ? `${currentAccount.genre[1]},`
                          : currentAccount.genre[1]
                      }  ${currentAccount.genre[2]}`
                    : ""}
                </span>
              </div>
              <div className={cl["card-row__dopinfo-item"]}>
                <p>Дата выхода</p>
                <span>{currentAccount.releaseDate}</span>
              </div>
              <div className={cl["card-row__dopinfo-item"]}>
                <p>Издатель</p>
                <span>{currentAccount.publisher}</span>
              </div>

              <br />
              <p className={cl["dopdopinf"]}>
                После покупки вы получите {currentAccount.platform} аккаунт с
                игрой и возможностью полной смены данных
              </p>
              <br />
              <p className={cl["dopdopinf"]}>
                Возможна покупка и на ваш аккаунт
              </p>
            </div>
            <div
              id={cl["card-row__slider-wrapper-offline"]}
              className={cl["card-row__slider-wrapper"]}
            >
              <div className={cl["card-row__slider-btns"]}>
                <div className={cl["slider-btns_what"]}>
                  <div
                    onClick={() => setOption(1)}
                    id={option == 1 ? cl["what-btn-selected"] : ""}
                    className={cl["what-btn"]}
                  >
                    СКРИНШОТЫ
                  </div>
                  <div
                    className={cl["what-btn"]}
                    onClick={() => setOption(2)}
                    id={option == 2 ? cl["what-btn-selected"] : ""}
                    style={currentAccount.video ? {} : { display: "none" }}
                  >
                    ТРЕЙЛЕР
                  </div>
                  {/* <div
                  className={cl["what-btn"]}
                  onClick={() => setOption(3)}
                  id={option == 3 ? "what-btn-selected2" : ""}
                >
                  DLC
                </div> */}
                </div>
              </div>

              {option == 1 ? (
                <MatchMediaWrapper
                  desktopContent={
                    <SliderFrorGamePage img={currentAccount.screenshots} />
                  }
                  mobileContent={
                    <MobileImgSlider img={currentAccount.screenshots} />
                  }
                />
              ) : (
                <div className={cl["card-slider"]}>
                  <VideoYb
                    videoLink={currentAccount.video ? currentAccount.video : ""}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={cl["card-about"]}>
            <div className={cl["card-about__header"]}>
              <div
                id={whatAbout == 1 ? cl["selected-option"] : ""}
                onClick={() => setWhatAbout(1)}
                className={cl["card-abut_option"]}
              >
                <span>ОБ ИГРЕ</span>
              </div>
              {/* <div
              className={cl["card-abut_option"]}
              id={whatAbout == 2 ? cl["selected-option"] : ""}
              onClick={() => setWhatAbout(2)}
            >
              <span>УСЛОВИЯ</span>
            </div> */}
              <div
                id={whatAbout == 3 ? cl["selected-option"] : ""}
                onClick={() => setWhatAbout(3)}
                className={cl["card-abut_option"]}
              >
                <span>АКТИВАЦИЯ</span>
              </div>
            </div>
            <div className={cl["card-about__content"]}>
              <p style={whatAbout == 1 ? {} : { display: "none" }}>
                {currentAccount.description}
              </p>
              <div
                style={whatAbout == 3 ? {} : { display: "none" }}
                className={cl["card-about__activation"]}
              >
                <li>
                  <strong>1</strong> Скачайте{" "}
                  <a
                    href={
                      currentAccount.platform == "Steam"
                        ? "https://store.steampowered.com/about/Steam?l=russian"
                        : "https://store.epicgames.com/ru/download"
                    }
                  >
                    {currentAccount.platform == "Steam"
                      ? "Steam"
                      : "Epic Games"}
                  </a>{" "}
                  , если он ещё не установлен)
                </li>
                <li>
                  <strong>2</strong> Войдите в него используя логин и пароль,
                  которые вы получите на почту после покупки
                </li>
                {
                  <li>
                    <strong>3</strong> Напишите в чат на сайте для смены
                    авторизационных данных
                  </li>
                }
                <li>
                  <strong>4</strong> Аккаунт в вашем полном распоряении
                </li>
              </div>
              <div
                style={whatAbout == 2 ? {} : { display: "none" }}
                className={cl["card-about__conditions"]}
              >
                <p>
                  <strong style={{ color: "rgba(225, 13, 13, 0.658)" }}>
                    Запрещено
                  </strong>
                  Использование любых видов читов в онлайн играх,а также смена
                  настроек безопасноси. При попытке смены пароля доступ к
                  аккаунт будет закрыт
                </p>
                <p>
                  <strong>Разрешено</strong>
                  Добавлять/удалать друзей и редактировать профиль
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrepaymentBlock
        display={prepaymentBlockVisibility}
        setDispay={setPrepaymentBlockVisibility}
        buy={buy}
        hidePrice={true}
      />
    </>
  );
});

export default AccountGamePage;
