import axios from "axios"
import { FC, memo, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

interface Props {
    relatedProductIds:{
        similar?:number[],
        series?:number[]
    }
}


const SimilarGames:FC<Props> = ({relatedProductIds}) => {
    const navigate = useNavigate()


    const [similar,setSimilar] = useState<null|any[]>(null)
    const [series,setSeries] = useState<null|any[]>(null)
    const [selectedTab,setSelectedTab] = useState<number>(relatedProductIds.series && relatedProductIds.series.length > 0 ? 2 : 1)
    const [selectedSlide,setSelectedSlide] = useState<number>(1)
    const [canScrollRight, setCanScrollRight] = useState<boolean>(false);
    const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
    const sliderRef = useRef<any>(null)
    const slideRef = useRef<any>(null)

    const scrollTo = ((direction:string) => {
        let curr = 0
        if(direction == 'right'){
            setSelectedSlide(prev => prev + 1)
            curr = sliderRef.current.scrollLeft + slideRef.current.offsetWidth + 15;
            sliderRef.current.scrollLeft =  curr;
        }else{
            setSelectedSlide(prev => prev - 1)
            curr = sliderRef.current.scrollLeft - slideRef.current.offsetWidth - 15
            sliderRef.current.scrollLeft = curr;
        }        
    })


    const getGamesData = async() => {
      if(selectedTab == 1&& !similar){
        const gamesData = await axios.post('https://api.steam-rent.com/getSimilarGamesData',{id:relatedProductIds.similar}).then(resp => resp.data)
        setSimilar(gamesData)
      }
      if(selectedTab == 2&& !series){
        const gamesData = await axios.post('https://api.steam-rent.com/getSimilarGamesData',{id:relatedProductIds.series}).then(resp => resp.data)
        setSeries(gamesData)
      }
      
    }

    useEffect(() => {
        getGamesData()
    },[selectedTab])
    
    useEffect(() => {

        if(selectedTab == 2){
            setCanScrollRight(false)
            setCanScrollLeft(false)
            return
        }
        if(selectedSlide > 1){
            setCanScrollLeft(true)
        }else{
            setCanScrollLeft(false)
        }   
        if(!similar){
            return
        }
        if(similar.length > 2 && selectedSlide + 2 <= similar.length){
            setCanScrollRight(true)
        }else{
            setCanScrollRight(false)
        }

       
    },[selectedSlide,selectedTab])

    
    return( 
        <div className="similar-games-container">
            <div className="similar-games-container__head">
            {relatedProductIds.similar && relatedProductIds.similar?.length > 0  && <div className="similar-games-container__btn"
                onClick={() => setSelectedTab(1)}
                id={selectedTab == 1 ? 'similar-games-container__btn_active' :''}
                >ПОХОЖИЕ ИГРЫ</div>}    
            {
            relatedProductIds.series && relatedProductIds.series?.length > 0  &&
                <div className="similar-games-container__btn"
                onClick={() => setSelectedTab(2)}
                id={selectedTab == 2 ? 'similar-games-container__btn_active' :''}>
                ИГРЫ СЕРИИ</div>
            }   
            </div>
           {canScrollRight && <img className="scroll-btn-right" src="https://img.icons8.com/ios/50/FFFFFF/more-than.png" onClick={(() => scrollTo('right'))} alt="" />}
           {canScrollLeft && <img className="scroll-btn-left" src="https://img.icons8.com/ios/50/FFFFFF/more-than.png" onClick={(() => scrollTo('left'))} alt="" /> } 
            <div ref={sliderRef} className="similar-games-container__slider">
                <div className="similar-games-container__row">
            {selectedTab == 1 && similar &&
                similar.map((item:any) => 
                    <div
                    onClick={() => navigate(`/shop/${item.id}`)}
                    key={item.img} ref={slideRef} className="similar-games-container__game-item">
                        <div className="similar-games-container__game-item__img">
                            <img src={item.img} alt="" />
                        </div>
                        <div className="similar-games-container__game-item__title">{item.title}</div>
                    </div>
                )
            }
           {selectedTab == 2 && series &&
                series.map((item:any) => 
                    <div
                        onClick={() => navigate(`/shop/${item.id}`)}
                        key={item.img} ref={slideRef} className="similar-games-container__game-item">
                        <div className="similar-games-container__game-item__img">
                            <img src={item.img} alt="" />
                        </div>
                        <div className="similar-games-container__game-item__title">{item.title}</div>
                    </div>
                )
            }
                </div>
            </div>
        </div>
    )
}


export default memo(SimilarGames)