import axios from "axios";
import { FC, useEffect, useState } from "react";
import Other from "../../store/Other";

const TopUpForm: FC<any> = ({
  currency,
  setIsTopUpModalOpen,
  isTopUpModalOpen,
  userId,
  userEmail,
}) => {
  const [avilableMethods, setAvilableMethods] = useState<any[] | null>(null);
  const [amount, setAmount] = useState<any>(currency === "$" ? 10 : 100);
  const [selectedMethod, setSelectedMethod] = useState<any | null>(null);
  const [isOk, setIsOk] = useState<boolean>(true);

  const getAvilableMethods = async () => {
    axios
      .post(`${process.env.REACT_APP_ServerLink}/getAvailablePaymentMethods`, {
        currency,
        amount,
        amountOfCashback: 0,
      })
      .then((resp) => setAvilableMethods(resp.data.avilableMethods));
  };

  const openLink = (url: string) => {
    const newWindow = window.open(url, "_blank");
    if (
      !newWindow ||
      newWindow.closed ||
      typeof newWindow.closed === "undefined"
    ) {
      window.location.href = url;
    }
  };

  const pay = async () => {
    axios
      .post(`${process.env.REACT_APP_ServerLink}/createPaymentLinkForRegUser`, {
        paymentMethod: selectedMethod,
        userId,
        currency,
        amount,
        userEmail,
      })
      .catch((e) => console.log(e))
      .then((resp: any) => openLink(resp.data.paymentLink));
  };

  useEffect(() => {
    getAvilableMethods();
  }, [currency, amount]);

  return (
    <div
      onClick={() => setIsTopUpModalOpen(false)}
      className="userpage-topup-modal"
      style={{ display: isTopUpModalOpen ? "flex" : "none" }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="userpage-topup-modal__container"
      >
        <div className="userpage-topup-modal__container__header"></div>
        <p className="topup-modal__title">Сумма пополнения</p>
        <div className="userpage-topup-modal__container__value-row">
          <input
            autoFocus={true}
            inputMode="numeric"
            pattern="\d*"
            className={`userpage-topup-modal__container__value-row__input ${
              amount < 1 &&
              !isOk &&
              "userpage-topup-modal__container__value-row__input_wrong"
            }`}
            value={amount}
            onChange={(e) => {
              if (/^\d*\.?\d*$/.test(e.target.value)) {
                setAmount(e.target.value);
              }
            }}
          />
          <div
            onClick={() => Other.changeCurrecy()}
            className="userpage-topup-modal__container__value-row__currency"
          >
            {currency}
          </div>
        </div>
        <p className="topup-modal__title">Способ оплаты</p>
        <div className="userpage-topup-modal__container__payment-methods">
          {avilableMethods?.map((method) => (
            <div
              key={method.id}
              className={`userpage-topup-modal__container__payment-methods__method ${
                selectedMethod !== null &&
                method.id === selectedMethod.id &&
                "userpage-topup-modal__container__payment-methods__method_selected"
              }`}
              onClick={() => setSelectedMethod(method)}
            >
              <img
                src={method.imgFromFront ? method.imgFromFront : method.img}
              />
              {method.title}
            </div>
          ))}
        </div>
        <p className="topup-modal__title">Минималки на оплату</p>
        {Other.initialC == "RU" && (
          <p>
            <span className="topup-modal__min">Карты RU - 100р</span>
          </p>
        )}
        <p>
          {" "}
          <span className="topup-modal__min"> Карты Казахстана - 4$</span>
        </p>
        {currency == "$" && (
          <p>
            {" "}
            <span className="topup-modal__min"> Карты Узбекистана - 3$</span>
          </p>
        )}
        <div className="userpage-topup-modal__container__footer">
          <div
            onClick={() =>
              amount >= 1 && selectedMethod ? pay() : setIsOk(false)
            }
            onAnimationEnd={() => setIsOk(true)}
            className={`userpage-topup-modal__container__footer__btn `}
          >
            Перейти к оплате
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopUpForm;
