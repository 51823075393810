import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IGame } from "../../types/types";
import "./GamePage.scss";
import Other from "../../store/Other";
import { observer } from "mobx-react-lite";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import GameImgSlider from "./GameImgSlider";
import GameAbout from "./GameAbout";
import SimilarGames from "./SimilarGames";
import RentalDurationSelect from "./RentalDurationSelect/RentalDurationSelect";
import PrepaymentForm from "../../Components/Product/PrepaymentForm/PrepaymentForm";
import Product from "../../store/Product";
import Seo from "../../Components/Seo";
import GameType from "./GameType/GameType";

const GamePageN = observer(() => {
  const navigate = useNavigate()
  const { gameId } = useParams();
  const { currency ,isAuth,openSideBar,logForServer} = Other;
  const {setCurrentGame,setSelectedOption,isGameAvailable,currentGame} = Product
  const [dlc,SetDlc] = useState(false)
  const [product, setProduct] = useState<IGame | null>(null);
  const [relatedProductIds,setRelatedProductIds] = useState(null)
  const [mnPrice,setMnPrice] = useState<number | null>(null)
  const [prepaymentBlockVisibility,setPrepaymentBlockVisibility] = useState(false)
  const [isAvilable,setIsAvilable] = useState<boolean>(true)

  const location = useLocation();
  const canonicalUrl = `https://steam-rent.com${location.pathname}`

  

  const fetchGame = async() => {    
    if(currentGame?.id == gameId){
      console.log('same game')
      setRelatedProductIds(currentGame?.relatedProductIds as any)
      return
    }
    try {
   const product = await  axios
   .post("https://api.steam-rent.com/getGameById", { gameId })
   .then((resp) => resp.data.game[0]);
   console.log('fetch')
   setCurrentGame(product)
   setRelatedProductIds(product.relatedProductIds)
   if(product.willBeAvilable > Date.now()){
    setIsAvilable(false)
   }

   ////
   logForServer({title:product.title,action:'OFFLINE'})

   if(product.dlc && product?.dlc?.length > 0){
    SetDlc(true)
   }

    } catch (e) {
      console.log(e);
    }
  };


  useEffect(() => {
    ///set Min Price
    if(!currentGame|| currentGame.isOnline){
      return
    }

    switch(currency){
      case '₴':
        console.log(currentGame.price.uahPerMonth)
        setSelectedOption({
          price: Number(currentGame.price.uahPerMonth),
          duration: "месяц",
        });
        setMnPrice(currentGame.price.uahPerMonth)
        break;
      case '$':
        setSelectedOption({
          price: Number(currentGame.price.usdPerMonth),
          duration: "месяц",      
        });
        setMnPrice(currentGame.price.usdPerMonth)
        break;
      default:
        setSelectedOption({
          price: Number(currentGame.price.rubPerMonth),
          duration: "месяц",      
        });
        setMnPrice(currentGame.price.rubPerMonth)
    }

  },[currency,currentGame])

  useEffect(() => {
    fetchGame();
  }, []);

  



  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
    {currentGame !== null &&
      <>
      {currentGame?.searchQuery ?
        <Seo canonicalUrl={canonicalUrl} title={currentGame.searchQuery} description={`Хотите поиграть в ${currentGame.title} прямо сейчас? Арендуйте ${currentGame.platform} аккаунт с игрой в нашем магазине. Удобные методы оплаты и приятные цены ждут вас!`} 
        schemaMarkup={{name:currentGame.title,brand:String(currentGame.publisher),description:currentGame.description,imageUrl:currentGame.img,price:currentGame.price.usdPerMonth ? currentGame.price.usdPerMonth : currentGame.price.usdPerWeek,sku:String(currentGame.id)}}
        />
        :
        currentGame?.isOnline ?
        <Seo canonicalUrl={canonicalUrl} title={`Аренда аккаунта ${currentGame.title} ${currentGame.platform}`} description={`Арендуйте ${currentGame.platform} аккаунт с игрой ${currentGame.title} без необходимости ждать. Удобные методы оплаты и приятные цены ждут ваc`}
        schemaMarkup={{name:currentGame.title,brand:String(currentGame.publisher),description:currentGame.description,imageUrl:currentGame.img,price:currentGame.price.usdPerMonth ? currentGame.price.usdPerMonth : currentGame.price.usdPerWeek,sku:String(currentGame.id)}}
         />
        :
        <Seo canonicalUrl={canonicalUrl} title={`Оффлайн активация ${currentGame.title}`} description={`Хотите поиграть в ${currentGame.title} прямо сейчас? Получите доступ к ${currentGame.platform} аккаунту в нашем магазине. Удобные методы оплаты и приятные цены ждут вас! (без очереди)`} 
        schemaMarkup={{name:currentGame.title,brand:String(currentGame.publisher),description:currentGame.description,imageUrl:currentGame.img,price:currentGame.price.usdPerMonth ? currentGame.price.usdPerMonth : currentGame.price.usdPerWeek,sku:String(currentGame.id)}}
        />}
      </>
    }
   <div className="game-pagee"
   onClick={() => navigate('/shop')}>
     <div className="game-page__wrapper">
      <div
       className="game-page__background"
       style={currentGame?{background:`linear-gradient(${currentGame.bcgColor},black)`}:{}}></div>
       {/* //////// */}
       
       <div
         className="content-container"
         onClick={(e) => e.stopPropagation()}
          >
                  {currentGame?.online?.price !== undefined && <GameType product={currentGame}/>}

          <div className="content-container-wrp">
            <div className="content-container__left ">
            <div className="content-container__left__img ">
              {currentGame && <img alt={`${currentGame.title} cover`} src={currentGame.img} />}
              {currentGame?.metaRating && (
                <div className="content-container__left__metacritic">
                  <span> {currentGame.metaRating}</span>
                  <p>Metacritic</p>
                </div>
              )}
            </div>
            <div className="content-container__left-details">
                <div className="content-container__left-details__item">
                    <dt>Жанр</dt>
                    <dd>{currentGame && currentGame.genre ? currentGame.genre.map(gnr => `${gnr}  `) : <Skeleton />}</dd>
                  </div>
  
                  <div className="content-container__left-details__item">
                    <dt>Дата выхода</dt>
                    <dd>{currentGame ? currentGame.releaseDate : <Skeleton />}</dd>
                  </div>
                  <div className="content-container__left-details__item">
                    <dt>Издатель</dt>
                    <dd>{currentGame ? currentGame.publisher : <Skeleton />}</dd>
                  </div>
            </div>
            {/* <GameFeatures/> */}
            {relatedProductIds && <SimilarGames relatedProductIds={relatedProductIds}/>}
            </div>
            <div className="content-container__right">
              <div className="content-container__right-details">
                  <img
                    src={require("../../assets/img/emptyHeart.png")}
                    id="favorite"
                    onClick={() =>
                      isAuth
                        ? navigate("/userpage/favorites")
                        : openSideBar(true)
                    }
                  />

                <div className="content-container__right-details__title">
                    <h1>
                      {currentGame ? `${currentGame.title} ${currentGame.isOnline ?'Аренда':'Активация'}` : <Skeleton count={2}/>}
                    </h1>
                </div>
                  
                <div className="content-container__right-details__availability">
                 {/* <li> Доступен для аренды</li> */}
                 {/* <li>Есть В наличии</li> */}
            
                 {isAvilable ?<li>Есть В наличии</li> :<li>Сейчас занят</li>
 }
                 {currentGame?.isOnline ? <li>Аренда аккаунта</li> :<li>Оффлайн активация</li>}                 {dlc  && <li>Есть DLC</li>}

                </div>
                { currentGame?.isOnline && <RentalDurationSelect pricee={currentGame?.price} setMnPrice={setMnPrice} online={false}/>}
                  <div className="content-container__right-details__price">
                    <button onClick={() =>  setPrepaymentBlockVisibility(true)}>
                      КУПИТЬ
                    </button>

                    {currentGame ? (
                     currentGame.free ? 
                     <>
                      <span>
                        0  {currency}
                      </span>
                      <span className="old-price">
                          {mnPrice} {currency}
                      </span>
                        <div className="discount-box">100%</div>
                     </>
                   :
                   currentGame.discount ?
                   <>
                    <span>
                        {mnPrice}  {currency}
                      </span>
                      <span className="old-price">
                        {(Number(mnPrice) + (Number(mnPrice) * (currentGame.discount / 100))).toFixed(2)}{currency}
                      </span>
                      <div className="discount-box">{currentGame.discount}%</div>
                   </>

                   :
                    <span>
                      {currentGame.isOnline && 'от'} {mnPrice} {currency}
                    </span>
                    ) : (
                      <Skeleton />
                    )}
                  </div>

                  <div className="content-container__right-details__subifo">
                    <div className="content-container__right-details__subifo__item">
                      <p>Поддержка языков</p>
                      <span className="subinfo-type__answ">
                        {currentGame ? currentGame.language : <Skeleton />}
                      </span>
                    </div>
                    <div className="content-container__right-details__subifo__item">
                      <p className="subinfo-type">
                        Тип
                        <div className="subinfo-type_tip">
                          {currentGame?.isOnline ?
                        <p>
                           Приобретая игру с данной меткой, вы будете единственным пользователем аккаунта на весь указанный срок
                          </p>
                          :
                          <p>Игры с этой меткой предназначены для одиночного прохождения. На аккаунте может быть несколько человек ,но для комфортной игры просто нажмите "Перейти в автономный режим". Все сохранения останутся у вас )</p>

                        }                         
                        </div>
                      </p>
                      <span className="subinfo-type__answ">
                        {currentGame ? (
                          currentGame.isOnline ? (
                            "Online"
                          ) : (
                            "Offline"
                          )
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </div>
                    <div className="content-container__right-details__subifo__item">
                      <p>Платформа</p>
                      <span className="subinfo-type__answ">
                        {currentGame ? (
                         currentGame.platform == "EGS" ? "Epic Games" : currentGame.platform
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </div>
                  </div>

                  {currentGame && currentGame.autoguard && <div className="content-container__right-details__message">
                  <img src={require("../../assets/img/lightning.png")} alt="" />   <p>Для этой игры доступно автоматическое получение кода</p>
                </div>}
                {currentGame && currentGame.free && <div className="content-container__right-details__message">
                  <img className="img-free" src={'https://img.icons8.com/external-vitaliy-gorbachev-fill-vitaly-gorbachev/60/FA5252/external-free-sales-vitaliy-gorbachev-fill-vitaly-gorbachev.png'} alt="" />   <p>Иногда мы бесплатно даем поиграть</p>
                </div>}
              </div>
              <GameImgSlider
                    screenshots={currentGame ? currentGame.screenshots : [null, null]}
                    video={currentGame ? currentGame.video : null}
                    title={currentGame ? currentGame.title : null}
                  />
            </div>
          </div>
          <GameAbout product={currentGame}/>
        </div>
        
    </div>
   </div>
   {prepaymentBlockVisibility && (
          <PrepaymentForm
            display={prepaymentBlockVisibility}
            setDispay={setPrepaymentBlockVisibility}
          />
        )}
  </SkeletonTheme>
  );
});



export default GamePageN;
