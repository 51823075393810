import axios from "axios";
import { makeAutoObservable } from "mobx";
import { buyGameAccountDgs } from "../Products/payment";
import { IGameAccount } from "../types/types";

class gameAccounts {
  currentAccount: IGameAccount | null = null;
  constructor() {
    makeAutoObservable(this);
  }

  setCurrentAccount = (account: IGameAccount | null) => {
    this.currentAccount = account;
  };

  // fetchAccounts = async () => {
  //   try {
  //     if (this.accounts === null) {
  //       this.accounts = await axios
  //         // .get("https://api.steam-rent.com/api/games")
  //         .get("http://localhost:8080/getGameAccounts")
  //         .then((resp) => resp.data.gameAccounts);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  buy = async (
    currency: string,
    initialC: string,
    onlineVerstion?: boolean
  ) => {
    if (this.currentAccount === null) return;
    axios.post("https://api.steam-rent.com/log", {
      text: `Someone trying to buy ACCOUNT through ${"DGS"}`,
      game: this.currentAccount.title,
      coutry: initialC,
    });
    buyGameAccountDgs(this.currentAccount.dgs, this.currentAccount?._id);
  };
}

export default new gameAccounts();
