import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Other from "../store/Other";
import Contacts from "./Contacts";

const Footer = () => {
  const navigate = useNavigate();
  const { changeCurrecy } = Other;

  const [contantsVisibility, setContantsVisibility] = useState(false);

  return (
    <footer>
      <div className="logo">
        <h3>SteamRent</h3>
      </div>

      <div className="contacts">
        <p
          style={contantsVisibility ? { color: "rgb(255, 132, 85)" } : {}}
          onClick={() => setContantsVisibility(!contantsVisibility)}
        >
          Контакты
        </p>
        <p onClick={() => navigate("/contract")}>Договор-Оферта</p>
        <p className="right"> All rights reserved ©2022</p>
      </div>
      <Contacts
        setContantsVisibility={setContantsVisibility}
        contantsVisibility={contantsVisibility}
      />
    </footer>
  );
};

export default Footer;
