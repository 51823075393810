import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Other from "../../store/Other";
import Product from "../../store/Product";
import RegUser from "../../store/RegUser";
import { IPaymentMethod } from "../../types/types";
import PaymentMethod from "./PaymentMethod";
import "./PaymentPage.scss";
import CartStore from "../../store/CartStore";

const PaymentPage2 = () => {
    const navigate = useNavigate();
    const { currency, initialC, openSideBar, isAuth } = Other;
    const {amount,pay} = CartStore

    const [avilableMethods, setAvilableMethods] = useState<IPaymentMethod[] | null>(null);
    const [selectedMethod, setSelectedMethod] = useState<null | {
    methodName: string;
    paymentSystem: string;
    }>(null);

const payFn = () => {
    if (selectedMethod?.paymentSystem === "p2p") {
        navigate("/p2p");
    }
    pay({method:selectedMethod?.methodName,paymentSystem:selectedMethod?.paymentSystem,currency})
    
};


useEffect(() => {
    axios
    .post(`${process.env.REACT_APP_ServerLink}/getAvailablePaymentMethods`, {
        currency,
        amount,
        amountOfCashback: 0,
    })
    .then((resp) => setAvilableMethods(resp.data.avilableMethods));
}, [currency]);


if(!avilableMethods || !amount){
    return <h1>loading</h1>
}

return (
    <div className="payment-page">
    <h3> Выберите способ оплаты</h3>
    <div className="payment-page__container">
        <div className="payment-page__container-list">
            {avilableMethods.map((method: any) => (
            <PaymentMethod
                key={method.id}
                title={method.title}
                img={method.img}
                imgFromFront={method.imgFromFront}
                isSelected={selectedMethod?.methodName == method.title}
                onClick={() =>
                    setSelectedMethod({
                    methodName: method.title,
                    paymentSystem: method.paymentSystem,
                    })
                }
            />
        ))}
        </div>
        <div className="payment-page__container-inf">
        {initialC !== "UA" && (
            <p className="payment-page__container-inf__nofif">
                Для оплаты зарубежными картами сейчас есть минималки,но вы можете{" "}
                <span
                onClick={() =>
                    isAuth ? navigate("/userpage") : openSideBar(true)
                }
                >
                пополнить
                </span>{" "}
                свой баланс и использовать его для будущих покупок
            </p>
            )}
            <div
            style={currency == "₴" ? { display: "none" } : {}}
            className="payment-page__container-inf__min"
            >
            {(amount < 97 && currency === "₽") ||
                (amount < 1.2 && currency === "$" && (
                <p>Карта 1.20{currency}</p>
                ))}
            {amount < 2.2 && currency === "$" && (
                <p>Карта Казахстан 2.20$</p>
            )}
            {amount < 4.5 && currency === "$" && (
                <p>Карта Беларусь 4.50$</p>
            )}
            </div>
        </div>
        <div
            onClick={() => selectedMethod && payFn()}
            className="payment-page__container__btn"
        >
            ОПЛАТИТЬ
        </div>
        </div>
    </div>
    );
};

export default PaymentPage2;
