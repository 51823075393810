import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { IGame, ISchemaMarkup } from "../types/types";

interface SeoProps {
  title: string;
  description: string;
  canonicalUrl?: string;
  schemaMarkup?: {
    name: string;
    description: string;
    price: number;
    imageUrl: string;
    sku: string;
    brand: string;
  };
}


const Seo: FC<SeoProps> = ({ title, description, canonicalUrl,schemaMarkup }) => {

  const schemaData:ISchemaMarkup | null = schemaMarkup
  ? {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": schemaMarkup.name,
      "description": schemaMarkup.description,
      "image": schemaMarkup.imageUrl,
      "sku": schemaMarkup.sku,
      "brand": {
        "@type": "Brand",
        "name": schemaMarkup.brand,
      },
      "offers": {
        "@type": "Offer",
        "url":  canonicalUrl || "",
        "priceCurrency": "USD", 
        "price": String(schemaMarkup.price),
        availability: "https://schema.org/InStock",
        "itemCondition": "https://schema.org/NewCondition",
      },
    }
  : null;

  console.log({canonicalUrl,schemaData});
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {schemaMarkup && (
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      )}
    </Helmet>
  );
};

export default Seo;
