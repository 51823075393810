import { observer } from "mobx-react-lite";
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Other from "../../store/Other";
import RegUser from "../../store/RegUser";
import CartStore from "../../store/CartStore";

const PrepaymentForm2 = observer(({ setDispay }: any) => {
const navigate = useNavigate();
const { userData } = RegUser;
const {title,amount,} = CartStore
const { currency, userEmail,setUserEmail} = Other;


const [emailSpalling, setEmailSpalling] = useState(true);


//Transfer Cashback manipulations to UserStore
const pay = () => {
    console.log('xxx')
    var re = /\S+@\S+\.\S+/;
    if (!re.test(userEmail)) {
        setEmailSpalling(false)
        return;
    }
    
    navigate("/payment2");
}   


return (
    <div
    onMouseDown={(e) => {
    e.stopPropagation();
    setDispay(false);
    }}
    className="prepayment-form"
>
        <div
        className="prepayment-form__container"
        onMouseDown={(e) => e.stopPropagation()}
        >
        <div className="prepayment-form__title">{title}</div>
        <p>После оплаты, на эту почту вы получите данные для входа</p>
            <div className="input-wrapper">
                <input
                    placeholder="Email"
                    type="text"
                    className="prepayment-form__input"
                    id={emailSpalling == true ? "" : "wrong-email-validadtion"}
                    onAnimationEnd={() => setEmailSpalling(true)}
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                />
            {localStorage.getItem("userEmail") && userEmail === "" && (
                <div
                className="email-tip"
                id="tip"
                onClick={() =>
                    setUserEmail(localStorage.getItem("userEmail") as string)
                }
                >
                {localStorage.getItem("userEmail")}
                </div>
            )}
            </div>
            <div className="prepayment-form__payment-inform">
                <div style={userData ? {visibility:'hidden'}:{}} className="prepayment-form__payment-inform__cashback">
                    <p> У вас нет кэшбэка, так как вы не зарегистрированы</p>
                </div>
                <p style={{ fontSize: "1.5em" }}>
                К оплате {amount}
                {currency}
                </p>
            </div>
            

        <div onClick={() => pay()} className="prepayment-form__pay-btn">
            ОПЛАТИТЬ
        </div>

        </div>
    </div>
    );
});

export default memo(PrepaymentForm2);
