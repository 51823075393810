import { FC, memo, useState } from "react";
import { IRegisteredUsers, IRegUserPaymentData } from "../../types/types";
import PaymentItem from "./PaymentItem";

interface props {
  userData: IRegisteredUsers;
}

const UserPayments: FC<props> = ({ userData }) => {
  const [isPaymentsListOpen, setIsPaymentsListOpen] = useState(false);

  return (
    <div className="user-page__container-payments">
      <div className="user-page__container-payments__header">
        МОИ ПОКУПКИ
        <div className="user-page__container-payments__header__line"></div>
      </div>
      <div className="user-page__container-payments__wrapper">
        <div className="user-page__container-payments__stat">
          <div className="stat-item">
            ПРОЦЕНТ КЭШБЭКА <span>10%</span>
          </div>
          <div className="stat-item">
            УРОВЕНЬ <span>1</span>
          </div>
        </div>
        <div className="user-page__container-payments__list">
          {userData.payments
            .slice(0, isPaymentsListOpen ? 100 : 3)
            .map((pm: IRegUserPaymentData) => (
              <PaymentItem paymentData={pm} />
            ))}
          {userData.payments.length > 3 && isPaymentsListOpen == false && (
            <div
              onClick={() => setIsPaymentsListOpen(true)}
              className="user-page__container-payments__list-btn"
            >
              ПОКАЗАТЬ ВСЕ
            </div>
          )}
          {userData.payments.length < 1 && (
            <div className="user-page__container-ref__wrapper__list">
              НЕТ ТРАНЗАКЦИЙ
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(UserPayments);
