import axios from "axios";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Seo from "../../Components/Seo";
import Other from "../../store/Other";
import RegUser from "../../store/RegUser";
import TopUpForm from "./TopUpForm";
import "./UserPage.scss";
import UserPayments from "./UserPayments";
import UserRef from "./UserRef";
import CurrencyChanger from "../../Components/CurrencyChanger";

const UserPage = observer(() => {
  const { currency, isAuth, logOut, setActivePage, convertedMoney } = Other;
  const { userData, getUserData } = RegUser;
  const navigate = useNavigate();

  const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);

  const daysWithUs = useMemo(() => {
    if (!userData) {
      return 1;
    }
    const millisecondsInDay = 24 * 60 * 60 * 1000;
    const currentDate: Date = new Date();
    const timeDifference: number = currentDate.getTime() - userData?.daysWithus;
    const daysPassed: number = Math.floor(timeDifference / millisecondsInDay);
    return daysPassed + 1;
  }, [userData]);

  useEffect(() => {
    setActivePage(10);
    getUserData(null);
  }, []);

  if (!isAuth) {
    return <h1></h1>;
  }

  if (userData == null) {
    return <h1></h1>;
  }

  return (
    <div className="user-page">
      <TopUpForm
        isTopUpModalOpen={isTopUpModalOpen}
        setIsTopUpModalOpen={setIsTopUpModalOpen}
        currency={currency}
        userEmail={userData.email}
        userId={userData.id}
      />
      <Seo
        title={`Ваш профиль ${userData.email}`}
        description="Это ваша личная страница"
      />

      <div className="user-page__container">
        <div className="user-page__container__header">
          <div className="user-page__container__header__balance">
            <p className="">
              <strong className="balance_b"> Баланс :</strong>

              <span>
                {convertedMoney(userData.cashback).toFixed(2)} {currency}
              </span>
             {userData.email == 'mishart@gmail.com' &&  <CurrencyChanger/>}

            </p>
            <div
              onClick={() => setIsTopUpModalOpen(true)}
              style={{ display: currency == "₴" ? "none" : "flex" }}
              className="topUp-btn"
            >
              Пополнить
            </div>
          </div>
          <div
            onClick={() => {
              logOut();
              navigate("/");
            }}
            className="user-page__container__header__logout-btn"
          >
            ВЫЙТИ
          </div>
        </div>
        {/* /////// */}
        <div className="user-page__container-stat">
          <div className="user-page__container-stat__icon">
            <img src={require("../../assets/icons/mem2.jpg")} alt="" />
          </div>
          <div className="user-page__container-stat__name">
            {userData.email}
            <p>уровень 1</p>
          </div>

          <div className="user-page__container-stat-statistic">
            <div className="user-page__container-stat-statistic-item">
              <div className="user-page__container-stat-statistic-item__count">
                <span>{userData.payments.length}</span>
              </div>
              <div className="user-page__container-stat-statistic-item__title">
                ПОКУПОК СОВЕРШЕНО
              </div>
            </div>
            <div className="user-page__container-stat-statistic-item">
              <div className="user-page__container-stat-statistic-item__count">
                <span>{userData.reviews}</span>
              </div>
              <div className="user-page__container-stat-statistic-item__title">
                Отзывов оставлено
              </div>
            </div>
            <div className="user-page__container-stat-statistic-item">
              <div className="user-page__container-stat-statistic-item__count">
                <span>{daysWithUs}</span>
              </div>
              <div className="user-page__container-stat-statistic-item__title">
                ДНЕЙ НА САЙТЕ
              </div>
            </div>
            <div className="user-page__container-stat-statistic-item">
              <div className="user-page__container-stat-statistic-item__count">
                <span>
                  {convertedMoney(userData.totalCashBack).toFixed(2)}
                  {currency}
                </span>
              </div>
              <div className="user-page__container-stat-statistic-item__title">
                ДЕНЕГ СЭКОНОМЛЕНО
              </div>
            </div>
          </div>
        </div>
        <UserPayments userData={userData} />
        {/* ////////// */}
        <UserRef userData={userData} />
      </div>
    </div>
  );
});

export default UserPage;
