import { configure, makeAutoObservable } from "mobx";
import { IGame } from "../types/types";
import axios from "axios";
import {
  buyDgs,
  buyDgsOnline,
  buyFk,
  buyPrimePayment,
  buyU,
} from "../Products/payment";

interface IGameConditions {
  price: number;
  duration: string;
}

configure({
  enforceActions: "never",
});
class Product {
  userEmail: string = "";
  currentGame: IGame | null = null;
  selectedOption: IGameConditions = { price: 0, duration: "" };
  amountWithouthCashback: number | null = null;
  promoStatus: boolean = false;
  gamePrice: any = null;
  onlineOfflineOption: number = 0;
  animation: boolean = true;
  promocode: null | string = null;
  isCashbackUsed: { status: boolean; amount: number | null } = {
    status: false,
    amount: 0,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setIsCashbackUsed = (newValue: any) => {
    this.isCashbackUsed = newValue;
    console.log(newValue);
  };

  setCurrentGame = (game: IGame | null) => {
    this.currentGame = game === null ? null : JSON.parse(JSON.stringify(game));
    this.gamePrice = game === null ? null : JSON.parse(JSON.stringify(game));
  };

  setUserEmail = (text: string) => {
    this.userEmail = text.toLowerCase();
  };

  setSelectedOption = (conditions: IGameConditions) => {
    this.selectedOption = conditions;
  };

  setPromoStatus = (newStatus: boolean) => {
    this.promoStatus = newStatus;
  };

  promoFn = (promoValue: string) => {
    this.promocode = promoValue;
    if (this.currentGame === null) {
      return;
    }
    let gameP = this.currentGame?.price;
    gameP.usdPerWeek = Number((gameP.usdPerWeek * 0.8).toFixed(2));
    gameP.usdPerMonth = Number((gameP.usdPerMonth * 0.8).toFixed(2));
    gameP.usdPerDay = Number((gameP.usdPerDay * 0.8).toFixed(2));
    gameP.rubPerWeek = Number((gameP.rubPerWeek * 0.8).toFixed(2));
    gameP.rubPerDay = Number((gameP.rubPerDay * 0.8).toFixed(2));
    gameP.rubPerMonth = Number((gameP.rubPerMonth * 0.8).toFixed(2));
    this.gamePrice = gameP;
  };

  buy = async (currency: string, initialC: string) => {
    const onlineVerstion = this.currentGame?.isOnline;
    if (this.currentGame == null) {
      return;
    }
    localStorage.setItem("userEmail", this.userEmail);
    if (this.userEmail !== "test@gmail.com") {
      axios.get("https://api.steam-rent.com/addPreTransaction");
    }
    if (this.currentGame.free) {
      axios.post("https://api.steam-rent.com/getFreeGame", {
        productId: this.currentGame.id,
        email: this.userEmail,
        title: this.currentGame.title,
        pass: "A87837A",
      });
      window.open("/successpayment");
      return;
    }

    let price = this.selectedOption.price;
    if (currency == "₴") {
      axios.post("https://api.steam-rent.com/sendWarnMassage", {
        email: this.userEmail,
        title: this.currentGame !== null && this.currentGame.title,
      });
      buyU(price, this.userEmail, {
        duration: this.selectedOption.duration,
        productId: this.currentGame.id,
        title: this.currentGame.title,
        isOnline: this.currentGame.isOnline,
      });
      return;
    }
    let method = "";
    const isPhone = window.matchMedia("(max-width: 520px)").matches;
    console.log(isPhone);
    if (
      (this.selectedOption.price > 1 && currency == "$") ||
      this.selectedOption.price > 90
    ) {
      if (this.selectedOption.price == 97) {
        this.selectedOption.price = 100;
      }
      buyPrimePayment(
        this.selectedOption.price,
        currency,
        this.currentGame.id,
        this.selectedOption.duration,
        this.userEmail,
        this.currentGame.title,
        this.currentGame.isOnline
      );

      return;
    }

    if (price > 90 && currency === "₽" && !isPhone) {
      method = "FK";
      // buyFk(
      //   this.selectedOption.price,
      //   currency,
      //   this.currentGame._id,
      //   this.selectedOption.duration
      // );
      !onlineVerstion == true
        ? buyDgs(
            this.currentGame.dgsID ? this.currentGame.dgsID : null,
            this.selectedOption.duration,
            this.currentGame._id,
            this.promoStatus
          )
        : buyDgsOnline(
            this.currentGame.online?.dgsID,
            this.selectedOption.duration,
            this.currentGame._id
          );
    } else {
      method = "DGS";
      !onlineVerstion == true
        ? buyDgs(
            this.currentGame.dgsID ? this.currentGame.dgsID : null,
            this.selectedOption.duration,
            this.currentGame._id,
            this.promoStatus
          )
        : buyDgsOnline(
            this.currentGame.online?.dgsID,
            this.selectedOption.duration,
            this.currentGame._id
          );
    }
    axios.post("https://api.steam-rent.com/log", {
      text: `Someone trying to buy through ${method}`,
      game: this.currentGame.title,
      coutry: initialC,
      amount: this.selectedOption.price,
      duration: this.selectedOption.duration,
      email: this.userEmail,
    });

    return;
  };

  changeOption = (selectedOpt: number) => {
    this.onlineOfflineOption = selectedOpt;
  };

  changeAnimation = (newStatus: boolean) => {
    setTimeout(() => {
      this.animation = newStatus;
    }, 1500);
  };

  resetAnim = () => {
    this.animation = true;
  };
  

  isGameAvailable = async  () => {
    const availability = await axios.post(`${process.env.REACT_APP_ServerLink}/isGameAvailable`,{id:this.currentGame?.id}).then(resp => resp.data.availability)
    return availability
  }

  buyWithCashback = async (
    currency: any,
    initialC: any,
    userId: any,
    paymentSystem: string,
    method: string,
    isOnlineVersion:any,
  ) => {
    console.log("payStart");
    if (this.currentGame == null) {
      console.log("productNotSelected");
      return;
    } 
    try {
      
      if (this.currentGame.free) {
        axios.post("https://api.steam-rent.com/getFreeGame", {
          productId: this.currentGame.id,
          email: this.userEmail,
          title: this.currentGame.title,
          pass: "A87837A",
        });
        window.open("/successpayment");
        return;
      }
      localStorage.setItem("userEmail", this.userEmail);

      ///selecteAmountWithoutCashback
      if (this.isCashbackUsed.amount !== null) {
        this.amountWithouthCashback = Number(
          (this.selectedOption.price - this.isCashbackUsed.amount).toFixed(2)
        );
      } else {
        this.amountWithouthCashback = this.selectedOption.price;
      }

      const link = await axios
        .post(`${process.env.REACT_APP_ServerLink}/payWithCashb`, {
          // const link = await axios
          //   .post("https://api.steam-rent.com/payWithCashb", {
          amount: this.selectedOption.price,
          currency,
          userId,
          amountOfCashback: this.isCashbackUsed.amount,
          paymentSystem: paymentSystem,
          method,
          userEmail: this.userEmail,
          productId: this.currentGame.id,
          duration: this.selectedOption.duration,
          productTitle: this.currentGame.title,
          isOnline: this.currentGame.isOnline,
          promocode: this.promocode,
          influencerRef:localStorage.getItem('utm_campaign'),
          isOnlineVersion,
        })
        .then((resp) => resp.data.currentLink);
      if (link !== "") {
        window.location.href = link;
      }
    } catch (e) {
      console.log("buyWithCashBackErr");
    }
  };

  payUsingBalance = async ({ currency, encryptedData }: any) => {
    try {
      //encryptedData == userId
      if (this.currentGame !== null) {
        let res = await axios.post(
          `${process.env.REACT_APP_ServerLink}/paybb`,
          {
            encryptedData,
            currency,
            amount: this.selectedOption.price,
            userEmail: this.userEmail,
            productId: this.currentGame.id,
            title: this.currentGame.title,
            duration: this.selectedOption.duration,
            isOnline: this.currentGame.isOnline,
          }
        );
        if (res.status == 200) {
          return true;
        }
        return false;
      }
    } catch (e) {
      console.log("err payUsingBalance ");
      console.log(e);
      return false;
    }
  };
}

export default new Product();
