import { makeAutoObservable, toJS } from "mobx";
import Other from "./Other";
import PSGame from "./PSGame";
import RegUser from "./RegUser";
import axios from "axios";

class CartStore {
    title:null|string = ''
    amount:number|null = 0 
    productCategory:string = ''

    constructor(){
        makeAutoObservable(this)
    }


    setProductData = (product:any,currency:string,productCategory:string) => {
        this.title = product.title
        switch(currency){
            case '$':
                this.amount = product.minPrice.USD
                break;
            case '₴':
                this.amount = product.minPrice.UAH
                break;
            default:
                this.amount = product.minPrice.RUB
        }
        this.productCategory = productCategory
    }

    
    pay = async({method,paymentSystem,currency}:any) => {
        const userEmail = Other.userEmail
        let currentProduct = null
        switch(this.productCategory){
            case 'PSGame':
                currentProduct = PSGame.currentPSGame
                break;   
                
            }
        
        

        const link = await axios
        .post(`${process.env.REACT_APP_ServerLink}/payWithCashb`, {
          category:this.productCategory,
          amount: this.amount,
          currency,
          userId:toJS(RegUser.userData?.id),
          amountOfCashback: 0,
          paymentSystem: paymentSystem,
          method,   
          userEmail,
          productId:currentProduct?._id,
          productTitle:currentProduct?.title,
          isOnline:currentProduct?.isOnline,
          //   duration: this.selectedOption.duration,
        //   promocode: this.promocode,
        })
        .then((resp) => resp.data.currentLink);
        console.log({link})
        if (link !== "") {
            window.location.href = link;
          }        
    }
}


export default new CartStore()