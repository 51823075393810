import { observer } from "mobx-react-lite";
import Other from "../store/Other";
import { useState } from "react";

const CurrencyChanger = observer(() => {
  const { currency } = Other;
  const [changing, setChanging] = useState(false);

  const changeCurrency = () => {
    setChanging(true);
    Other.changeCurrecy();
  };
  return (
    <span
      className={changing ? "rotateY__anim" : ""}
      onClick={() => changeCurrency()}
      onAnimationEnd={() => setChanging(false)}
    >
      {currency}
    </span>
  );
});

export default CurrencyChanger;
