import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Other from "../../store/Other";
import "./MobileMenu.scss";

const MobileMenu = () => {
  const { pathname } = useLocation();
  const { activePage, openSideBar, isAuth, initialC } = Other;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);
  return (
    <nav
      id={!isOpen ? "mobile-menu_close" : "mobile-menu_open"}
      className="mobile-menu"
    >
      <input
        id="menu__tgl"
        checked={isOpen}
        onChange={() => setIsOpen(!isOpen)}
        type="checkbox"
      />
      <label className="menu__btn" htmlFor="menu__tgl">
        <span></span>
      </label>

      <div
        style={isAuth ? { visibility: "hidden" } : {}}
        onClick={() => {
          openSideBar(true);
          setIsOpen(false);
        }}
        className="mobile-menu__icons"
      >
        войти
      </div>

      <ul className="mobile-menu__list">
        {isAuth && (
          <li>
            <Link
              id={activePage == 2 ? "menu-selected" : ""}
              className="mobile-menu__item"
              to="/userpage"
            >
              Профиль
            </Link>
          </li>
        )}
       
          <Link className="mobile-menu__item-ps" to="/shop/ps">
            Playstation
          </Link>
        
        <li>
          <Link
            id={activePage == 2 ? "menu-selected" : ""}
            className="mobile-menu__item"
            to="/shop"
          >
            Магазин
          </Link>
        </li>
        <li>
          <Link
            className="mobile-menu__item"
            id={activePage == 2 ? "menu-selected" : ""}
            to="/"
          >
            Главная
          </Link>
        </li>
        <li>
          <Link
            id={activePage == 4 ? "menu-selected" : ""}
            className="mobile-menu__item"
            to="/reviews"
          >
            Отзывы
          </Link>
        </li>
        <li>
          <Link className="mobile-menu__item" to="/guards">
            Код
          </Link>
        </li>
        {/* <li> */}

        {/* </li> */}
      </ul>
    </nav>
  );
};

export default MobileMenu;
