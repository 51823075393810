import { FC, memo, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Other from "../../store/Other";
import { observer } from "mobx-react-lite";
import CurrencyChanger from ".././CurrencyChanger";
import "./Header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";

const shop = require("../../assets/img/shop.png");

const Header: FC = observer(() => {
  const {
    activePage,
    openSideBar,
    isSidebarActive,
    isAuth,
    initialC,
    currency,
    identifyTrafficSource
  } = Other;

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    identifyTrafficSource(searchParams)
  },[])

  return (
    <header className="main-header">
      <div className="header-menu">
        <div
          style={{ position: "relative" }}
          className={`shop-select-wrapper ${
            activePage === 2 && "underline-anim underline-anim-true"
          }`}
        >
          <p className="menu-item">Магазин</p>
          <div className="shop-select">
            <Link to={"/shop"}>
              <div className="shop-select_option">Аренда</div>
            </Link>
            <Link to={"/gameaccounts"}>
              <div className="shop-select_option">Покупка</div>
            </Link>{" "}
            {["MD", "RU"].includes(initialC) && (
              <a
                href={
                  "https://www.oplata.info/asp2/pay_wm.asp?id_d=3756669&ai=1113898"
                }
              >
                <div className="shop-select_option ">Пополнить Steam</div>
              </a>
            )}
            {currency !== "₴" && (
              <a
                href={
                  "https://www.oplata.info/asp2/pay_wm.asp?id_d=4265401&ai=1113898"
                }
              >
                <div className="shop-select_option " id="valorant">
                VALORANT
                </div>
              </a>
            )}
          </div>
        </div>
        <Link
          to="shop/ps"
          className={
            activePage == 11
              ? "underline-anim underline-anim-true"
              : "underline-anim"
          }
          // onClick={(e) => initialC == "UA" && e.preventDefault()}
        >
          <p className="menu-item menu-item_ps">PlayStation</p>
        </Link>
        <Link
          to="/news"
          className={
            activePage == 3
              ? "underline-anim underline-anim-true"
              : "underline-anim"
          }
        >
          <div className="shop-select-wrapper">
          <p className="menu-item">Новости</p>
          <div className="shop-select">
            {/* <Link to={"/shop"}> */}
              <div className="shop-select_option">Новости</div>
            {/* </Link> */}
            <Link to={"/collections"}>
              <div className="shop-select_option">Подборки</div>
            </Link>
            </div>
          </div>
        </Link>
        <Link
          to="/reviews"
          className={
            activePage == 4
              ? "underline-anim underline-anim-true"
              : "underline-anim"
          }
        >
          <p className="menu-item">Отзывы</p>
        </Link>
        <Link
          to={"/guards"}
          className={
            activePage == 10
              ? "underline-anim underline-anim-true"
              : "underline-anim"
          }
        >
          <p className="menu-item">Код</p>
        </Link>
        <Link
          to={"/"}
          className={
            activePage == 1
              ? "underline-anim underline-anim-true"
              : "underline-anim"
          }
        >
          <p className="menu-item">Главная</p>
        </Link>

        {/* <img
          className="header-favrite"
          src={require("../../assets/img/heart2.png")}
          alt=""
        /> */}
        {/* <CurrencyChanger /> */}
        <img
          className="header-favrite"
          src={require("../../assets/img/heart2.png")}
          onClick={() =>
            isAuth ? navigate("/userpage/favorites") : openSideBar(true)
          }
        />
        <>
          {!isAuth ? (
            <div
              onClick={() => openSideBar(true)}
              className={`header-login ${
                isSidebarActive == true && "header-login-btn_active"
              }`}
            >
              войти
            </div>
          ) : (
            <FontAwesomeIcon
              icon={faUser}
              color="white"
              size={"1x"}
              className="header-userIcon"
              onClick={() => navigate("/userpage")}
            />
          )}
        </>
      </div>
    </header>
  );
});

export default memo(Header);
