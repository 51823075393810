import { FC, memo, useEffect, useMemo, useState } from 'react'
import Other from '../../../store/Other'
import { IGame } from '../../../types/types'
import './GameType.scss'
import { useLocation, useNavigate } from 'react-router-dom'

interface props{
    product:IGame
}


const GameType:FC<props> = ({product}) => {
    const navigate = useNavigate()
    const {currency} = Other
    const location = useLocation();
    const [selectedOption,setSelectedOption] = useState(location.pathname.includes('rent') ? 2:1)
    const minOfflinePrice = useMemo(() => {
        switch(currency){
            case '$':
                return product.price.usdPerMonth
            case '₴':
                return product.price.uahPerMonth
            default:
                return product.price.rubPerMonth
        }
    },[currency])

    const minOnlinePrice = useMemo(() => {
        if(!product.online?.price){
            return 0
        }
        switch(currency){
            case '$':
                return product.online?.price.usd.day ? product.online?.price.usd.day : product.online?.price.usd.week ? product.online?.price.usd.week : product.online?.price.usd.month
            case '₴':
                return product.online?.price.uah.day ? product.online?.price.uah.day : product.online?.price.uah.week ? product.online?.price.uah.week : product.online?.price.uah.month
            default:
                return product.online?.price.r.day ? product.online?.price.r.day : product.online?.price.r.week ? product.online?.price.r.week : product.online?.price.r.month
        }
    },[currency])

  

    return(
       <div className="game-type">
         <div className="game-type__container">
            <div id={selectedOption == 1?'game-type__option_selected':''}
                    onClick={() => navigate(`/shop/${product.id}`)}
                    className="game-type__option">
                <div className="game-type__option__title">Offline</div>
                <div className="game-type__option__price">{minOfflinePrice} {currency}</div>
            </div>
            <div id={selectedOption == 2 ?'game-type__option_selected':''} className="game-type__option">
                <div className="game-type__option__title"
                onClick={() => {navigate(`/shop/rent/${product.id}`);}}
                >Online</div>
                <div className="game-type__option__price">от {minOnlinePrice} {currency}</div>
            </div>
        </div>
       </div>
    )
}

export default GameType