import React, { useContext, useState } from "react";
import Product from "../../store/Product";
import { observer } from "mobx-react-lite";
import Other from "../../store/Other";

interface PrepaymentBlockProps {
  display: boolean;
  isOnline?: boolean;
  setDispay: (s: any) => void;
  buy: (currency: string, initialC: string, isOnline?: any) => void;
  hidePrice?: boolean;
}

const PrepaymentBlock: React.FC<PrepaymentBlockProps> = observer(
  ({ display, setDispay, isOnline, buy, hidePrice }) => {
    const [emailSpelling, setEmailSpalling] = useState<boolean>(true);
    const { userEmail, setUserEmail, selectedOption } = Product;
    const { currency, initialC } = Other;
    var re = /\S+@\S+\.\S+/;
    const pay = () => {
      if (userEmail === "mishalox@gmail.com") {
        setEmailSpalling(false);
        return;
      }
      if (re.test(userEmail)) {
        buy(currency, initialC, isOnline);
        setDispay(false);
        setEmailSpalling(true);
        setUserEmail("");
        return;
      }
      setEmailSpalling(false);
    };

    return (
      <div
        className="prepayment__block_bc"
        onMouseDown={(e) => {
          e.stopPropagation();
          setDispay(false);
        }}
        style={
          display
            ? { backdropFilter: "blur(5px) brightness(50%)" }
            : { visibility: "hidden" }
        }
      >
        <div
          style={
            display
              ? { opacity: "0.95" }
              : { opacity: 0, transform: "translateY(-10px)" }
          }
          onMouseDown={(e) => e.stopPropagation()}
          className="prepayment__block"
        >
          <p>Введите почту, на которую будут отправлены данные для входа:</p>

          <input
            style={
              emailSpelling == false ? { borderBottom: "solid 3px red" } : {}
            }
            placeholder="Email"
            className="prepayment__input"
            type="email"
            onChange={(e) => setUserEmail(e.target.value.toLocaleLowerCase())}
            value={userEmail}
            id={emailSpelling ? "" : "shake"}
          />
          {!hidePrice ? (
            <span>
              {selectedOption.price} {currency}
            </span>
          ) : (
            <br />
          )}
          <div onClick={pay} style={{ color: "white" }} className="orange__btn">
            OK
          </div>
          {localStorage.getItem("userEmail") && userEmail === "" && (
            <div
              className="email-tip"
              id="tip"
              onClick={() =>
                setUserEmail(localStorage.getItem("userEmail") as string)
              }
            >
              {localStorage.getItem("userEmail")}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default React.memo(PrepaymentBlock);
