import { useState, useEffect, FC } from "react";

interface TimerProps {
  min: number;
  timeIsOver: (boolean: boolean) => void;
}

const Timer: FC<TimerProps> = ({ min, timeIsOver }) => {
  const [timeLeft, setTimeLeft] = useState(min * 60); // 15 minutes in seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          timeIsOver(true);
          // Здесь можно добавить действие, которое нужно выполнить по истечении времени
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div>
      <p>
        {`${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`}
      </p>
    </div>
  );
};

export default Timer;
