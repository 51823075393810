import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import PSGame from "../../store/PSGame";
import ShopPSItem from "./ShopPSItem";

const ShopPSList = observer(() => {
  const { fetchPSGames, sortedPSGames } = PSGame;

  useEffect(() => {
    fetchPSGames();
  }, []);

  if (sortedPSGames == null) {
    return <p>asd</p>;
  }
  return (
    <div
      onClick={() => console.log(sortedPSGames.length)}
      className="ps-shop-page__list-wrapper"
    >
      {sortedPSGames.map((game) => (
        <ShopPSItem game={game} key={game._id} />
      ))}
    </div>
  );
});

export default ShopPSList;
