import axios from "axios";
import { observer } from "mobx-react-lite";
import { memo, useEffect, useState } from "react";
import Other from "../../store/Other";
import RegUser from "../../store/RegUser";
import "./LoginSidebar.scss";

const LoginSidebar = observer(() => {
  const { isSidebarActive, openSideBar, auth, initialC } = Other;
  const { getUserData } = RegUser;
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [userEmail, setUserEmail] = useState<string>("");
  const [isEmailOk, setIsEmailOk] = useState<boolean>(true);
  const [userPass, setUserPass] = useState<string>("");
  const [isPassOk, setIsPassOk] = useState<boolean>(true);
  //
  const [isOk, setIsOk] = useState<{ status: boolean; inf: string }>({
    status: true,
    inf: "Неверный логин или пароль",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const registration = async () => {
    if (isLoading) {
      return;
    }
    setIsEmailOk(true);
    setIsPassOk(true);
    setIsLoading(true);
    let isOk = true;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userEmail)) {
      setIsEmailOk(false);
      isOk = false;
    }
    if (userPass.length < 5) {
      setIsPassOk(false);
      isOk = false;
    }
    if (!isOk) {
      setIsLoading(false);
      return;
    }

    const resp = await axios
      .post("https://api.steam-rent.com/user/registration", {
        userEmail: userEmail.toLowerCase(),
        userPass,
        initialC,
      })
      .then((resp) => resp.data);
    setIsLoading(false);
    if (!resp.isSuccess) {
      setIsOk({ status: false, inf: "Данный пользователь уже существует" });
      return;
    }
    openSideBar(false);
  };

  const login = async () => {
    if (isLoading) {
      return;
    }
    setIsOk({
      status: true,
      inf: "Неверный логин или пароль",
    });
    setIsLoading(true);
    const answ = await axios
      .post("https://api.steam-rent.com/user/login", {
        userEmail: userEmail.toLowerCase(),
        userPass,
      })
      .then((resp) => resp.data);
    if (answ.isSuccess) {
      openSideBar(false);
      auth(answ.userId);
      getUserData(answ.userId);
      localStorage.setItem("userEmail", userEmail);
    } else {
      setIsOk({
        status: false,
        inf: answ.status,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsOk({
      status: true,
      inf: "Неверный логин или пароль",
    });
    setIsEmailOk(true);
    setIsPassOk(true);
    setIsLoading(false);
  }, [selectedTab]);

  return (
    <div
      className={`auth-sidebar ${
        isSidebarActive == true && "auth-sidebar_active"
      }`}
    >
      <div
        onClick={() => openSideBar(false)}
        className="header-login header-login-btn"
      >
        ВОЙТИ
      </div>
      <div className="auth-sidebar__wrapper">
        <div className="auth-sidebar__wrapper-tabs">
          <div
            className={`auth-sidebar__wrapper-tabs-btn ${
              selectedTab == 1 && "auth-sidebar__wrapper-tabs-btn_active"
            }`}
            onClick={() => setSelectedTab(1)}
          >
            ВХОД
          </div>
          <div
            className={`auth-sidebar__wrapper-tabs-btn ${
              selectedTab == 2 && "auth-sidebar__wrapper-tabs-btn_active"
            }`}
            onClick={() => setSelectedTab(2)}
          >
            РЕГИСТРАЦИЯ
          </div>
        </div>
        <div className="auth-sidebar__wrapper-form">
          {!isOk.status && (
            <div className="auth-sidebar__wrapper-form-notification">
              {/* <h3>Неверный логин или пароль</h3> */}
              <h3>{isOk.inf}</h3>
            </div>
          )}
          {/* <div
            className="auth-sidebar__wrapper-form-notification"
            style={isOk != true ? { visibility: "visible" } : {}}
          >
            <h3>Неверный логин или пароль</h3>
          </div> */}

          <div className="login-input__wrapper">
            <input
              id="auth_email"
              className={`login-input ${!isEmailOk && "login-input_incorrect"}`}
              type="email"
              onChange={(e) => setUserEmail(e.target.value)}
              value={userEmail}
              autoComplete="off"
            />
            <label
              placeholder="E-MAIL"
              htmlFor="auth_email"
              className={`${
                userEmail.length == 0 && "form__label_valid"
              } form__label`}
            >
              E-MAIL
            </label>
          </div>
          <div className="login-input__wrapper">
            <input
              id="auth_pass"
              className={`login-input ${!isPassOk && "login-input_incorrect"}`}
              type="password"
              onChange={(e) => setUserPass(e.target.value)}
              value={userPass}
              autoComplete="off"
            />
            <label
              placeholder="E-MAIL"
              htmlFor="auth_pass"
              className={`${
                userPass.length == 0 && "form__label_valid"
              } form__label`}
            >
              ПАРОЛЬ
            </label>
          </div>
          <div className="auth-sidebar__wrapper-form-text">
            <p>Только зарегистрированные пользователи получают кэшбэк =)</p>
          </div>
          <div className="auth-sidebar__wrapper-form-btn__wrapper">
            <div
              className={`auth-sidebar__wrapper-form-btn ${
                isLoading && "auth-sidebar__wrapper-form-btn_loading"
              }`}
              onClick={() => (selectedTab === 1 ? login() : registration())}
            >
              {selectedTab === 1 ? "АВТОРИЗОВАТЬСЯ" : "ЗАРЕГИСТРИРОВАТЬСЯ"}
            </div>
          </div>
          {selectedTab === 1 && (
            <div className="auth-sidebar__wrapper-form__reset">
              <p>Забыли пароль?</p>
              <span onClick={() => alert("Напишите в чат")}>Восстановить</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default memo(LoginSidebar);
