import { FC, memo, useEffect, useMemo, useState } from "react";
import { IGame } from "../../types/types";
import Skeleton from "react-loading-skeleton";
// import  "../PSGameRentPage/PSGameRentPage.scss";



interface Props{
    product:IGame|null
    onlineVersion?:boolean
}

const GameAbout:FC<Props> = ({product,onlineVersion}) => {
    const [selectedTab, setSelectedTab] = useState(1);
    const [systemOption,setSistemOpetion] = useState(1)

    const link:string = useMemo(() =>{
        switch(product?.platform){
            case 'Steam':
              return    'https://store.steampowered.com/about/Steam?l=russian'
            case 'EGS':
              return    'https://store.epicgames.com/ru/download';
              default:
                return ''
        }
    },[product])

  
    return(
    <div className="game-page__about-wrapper">
      <div className="game-page__about-head">
        <div
          id={
            selectedTab == 1
              ? "game-page__about-head__tab_selected"
              : ""
          }
          onClick={() => setSelectedTab(1)}
          className="game-page__about-head__tab"
        >
          Об игре
        </div>
        <div
          id={
            selectedTab == 2
              ? "game-page__about-head__tab_selected"
              : ""
          }
          onClick={() => setSelectedTab(2)}
          className="game-page__about-head__tab"
        >
          Активация
        </div>
        <div
          id={
            selectedTab == 3
              ? "game-page__about-head__tab_selected"
              : ""
          }
          onClick={() => setSelectedTab(3)}
          className="game-page__about-head__tab"
        >
          Условия
        </div>
      </div>
      <div
        style={{ borderRadius: selectedTab == 1 ? "" : "8px" }}
        className="game-page__about-inform"
      >
        <p>{!product && <Skeleton count={5} />}</p>
        {product && selectedTab == 1 && (
          <>
          {product.descriptionTitle &&  <h3>{product.descriptionTitle}</h3>}
            <p>{product.description}</p>

            {product.description2 && <p style={{marginTop:'15px'}}>{product.description2}</p>
          }
            {/* <p>В игре нестандартным образом реализован гибрид ролевой игры и стратегии — у вас нет стартовых бонусов, вы такой же гражданин мира, как и все встреченные вами враги и недруги. Поэтому не стоит задирать нос, лучше бросить все внимание на свои реальные навыки. Чтобы укрепить свои позиции, постройте базу, где можно не только прятаться, но и крафтить полезное снаряжение, а также вести бизнес.</p> */}
            <div className="about-inform-dlc">  
            {product.minimumRequirements && product.recommendedRequirements &&  <section  className="system-requirements">      
              <div className="system-requirements__wrapper">
                <div className="system-requirements__list">
                <div className="system-requirements__btn">
                    Минимальные требования
                  </div>
                <div className="system-requirements__item">
                  <dt>ОС</dt>
                  <dd>{product.minimumRequirements.os}</dd>
                </div>
                <div className="system-requirements__item">
                  <dt>Процессор</dt>
                  <dd>{product.minimumRequirements.processor}</dd>
                </div>
                <div className="system-requirements__item">
                  <dt>Оперативная память</dt>
                  <dd>{product.minimumRequirements.memory}</dd>
                </div>
                <div className="system-requirements__item">
                  <dt>Видеокарта</dt>
                  <dd>{product.minimumRequirements.graphics}</dd>
                </div>
                <div className="system-requirements__item">
                  <dt>Место на диске</dt>
                  <dd>{product.minimumRequirements.storage}</dd>
                </div>
              </div>
              
              <div className="system-requirements__list">
                <div className="system-requirements__btn" id={systemOption == 1 ? "system-requirements__btn_activee" : ""}
                      onClick={() => setSistemOpetion(2)}
                  >
                    Рекомендуемые требования
                  </div> 
                <div className="system-requirements__item">
                  <dt>ОС</dt>
                  <dd>{product.recommendedRequirements.os}</dd>
                </div>
                <div className="system-requirements__item">
                  <dt>Процессор</dt>
                  <dd>{product.recommendedRequirements.processor}</dd>
                </div>
                <div className="system-requirements__item">
                  <dt>Оперативная память</dt>
                  <dd>{product.recommendedRequirements.memory}</dd>
                </div>
                <div className="system-requirements__item">
                  <dt>Видеокарта</dt>
                  <dd>{product.recommendedRequirements.graphics}</dd>
                </div>
                <div className="system-requirements__item">
                  <dt>Место на диске</dt>
                  <dd>{product.recommendedRequirements.storage}</dd>
                </div>
              </div>
              
              </div>
                
            </section >}

              {product.dlc?.map(dlc => 
                <div className="about-inform-dlc__item">{dlc.title}</div>
              )}


            {/* <div className="steam-comment">
              10/10 В этой игре прекрасно все боевка мое почтение, локации хочется облизывать вдоль и поперек, визуал оргазм для глаз. Рекомендую абсолютно всем
            </div>

            <div className="steam-comment">
              Великолепная, красивая, захватывающая, проработанная во всех отношениях игра, шедевр. Нет повестки, есть идея и уникальный сеттинг, хотелось бы полную локализацию на русский язык. Но.... Рекомендую. Привет из России.
            </div> */}

            
              
            </div>
             {!product.isOnline &&  <div className="game-page__about-inform-avilability">{!onlineVersion &&<span>Доступ к аккаунту на 3 месяца</span>}</div>}
            
          </>
        )}
        {product && selectedTab == 2 && (
        product.isOnline ?  
        <>
        <div className="about-inform__item">
            <strong>1</strong>
            <li>
            Скачайте <a href={link}>{product.platform}</a> , если он ещё не установлен)
            </li>
          </div>
          <div className="about-inform__item">
            <strong>2</strong>
            <li>
            Войдите в него используя логин и пароль, которые вы получите на почту после покупки
            </li>
          </div>
          <div className="about-inform__item">
            <strong>3</strong>
            <li>
            Написать в чат на сайте ,если потребуется доп пароль
            </li>
          </div>
          <div className="about-inform__item">
            <strong>4</strong>
            <li>
            Перейти во вкладку "Игры", найти игру в списке и загрузить её на свой ПК
            </li>
          </div>
          <div className="about-inform__item">
            <strong>5</strong>
            <li>После загрузки вы сможете начать наслаждаться игрой</li>
          </div>
        </>
        :
        <>
          <div className="about-inform__item">
            <strong>1</strong>
            <li>
            Скачайте <a href={link}>{product.platform == 'EGS' ? 'Epic Games' : product.platform}</a> , если он ещё не установлен
            </li>
          </div>
          <div className="about-inform__item">
            <strong>2</strong>
            <li>
            Войдите в него используя логин и пароль, которые вы получите на почту после покупки
            </li>
          </div>

          <div className="about-inform__item">
            <strong>3</strong>
            <li>

                {product.autoguard ?  `Нажмите на код,чтобы получить код или напишите в чат`:' Написать в чат на сайте ,если потребуется доп пароль'}
            </li>
          </div>

          <div className="about-inform__item">
            <strong>4</strong>
            <li>
            Перейти во вкладку "Игры", найти игру в списке и загрузить её на свой ПК </li>
          </div>

           <div className="about-inform__item">
            <strong>5</strong>
            <li>Зайти 1 раз в игру до появления меню и выйти </li>
          </div>

          <div className="about-inform__item">
            <strong>6</strong>
            <li>
            {product.platform == 'Steam' ? 'В программе Steam, в верхнем левом углу нажать steam - Перейти в автономный режим':'Перейти в оффлайн'}
            </li>
          </div> 

          <div className="about-inform__item">
            <strong>7</strong>
            <li>
            Теперь можно запускать игру из библиотеки и играть
            </li>
          </div>

          

        </>
        )}
        {product && selectedTab == 3 && (

          product.isOnline?
          <>
            <li className="about-inform__condition">
              - Запрещено менять данные аккаунта. Попытка смены данных приведет
              к немедленной блокировке аккаунта.
            </li>
            <li className="about-inform__condition">
              {" "}
              - Запрещено заходить на учетную запись через браузер компьютера.
            </li>
            <li className="about-inform__condition">
              - Аккаунт устанавливается только на одну консоль.
            </li>
          </>
          :
          <>
          <li className="about-inform__condition">
            - Запрещено менять данные от учетной записи
          </li>
          {/* <li className="about-inform__condition">
            - Запрещено делать аккаунт домашним (включать "Общий доступ"/ делать систему основной)
          </li> */}

          <li className="about-inform__condition">
            - Запрещено заходить в аккаунт через браузер
          </li>

          <li className="about-inform__condition">
            - Запрещено использовать читы в онлайн играх
          </li>

          <li className="about-inform__condition">
           - 1 Устройство
          </li>
          
          </>
            
        )}
      </div>
    </div>
    )
}


export default memo(GameAbout)