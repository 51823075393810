import React, { useRef, useState } from "react";
import ImgWithPreloader from "../ImgWithPreloader";

const SliderFrorGamePage = ({ img, dlc, edition }: any) => {
  const sliderRef = useRef<any>(null);
  const [startX, setStartX] = useState(-1);
  const [click, setClick] = useState<boolean>(false);
  const [prevValue, setPrevValue] = useState(0);

  const onMouseMove = (e: MouseEvent) => {
    if (click) {
      sliderRef.current.scrollLeft = startX - e.clientX + prevValue;
    }
  };

  return (
    <div
      ref={sliderRef}
      onMouseMove={(e: any) => {
        onMouseMove(e);
      }}
      onMouseDown={(e: any) => {
        setStartX(e.pageX);
        setClick(true);
      }}
      onMouseLeave={() => setClick(false)}
      onMouseUp={() => {
        setClick(false);
        setPrevValue(sliderRef.current.scrollLeft);
      }}
      className="card-slider"
    >
      {img ? (
        img.map((scrc: string) => <ImgWithPreloader src={scrc} />)
      ) : dlc ? (
        dlc.map((dlc: any) => <ImgWithPreloader src={dlc.screenshot} />)
      ) : (
        <img
          id="slide__img"
          key={edition}
          src={edition}
          draggable={false}
          alt=""
        />
      )}
    </div>
  );
};

export default React.memo(SliderFrorGamePage);
