import { observer } from "mobx-react-lite";
import { FC, memo, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import Other from "../../store/Other";
import PSGame from "../../store/PSGame";
import { IPSGame } from "../../types/types";

interface props {
  game: IPSGame;
}

const ShopPSItem: FC<props> = observer(({ game }) => {
  const { currency } = Other;
  const { inView, ref } = useInView();
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  const minPrice = useMemo(() => {
    switch (currency) {
      case "$":
        return game.minPrice.USD;
      case "₴":
        return game.minPrice.UAH ? game.minPrice.UAH : null;
      default:
        return game.minPrice.RUB;
    }
  }, [currency]);

  return (
    <div ref={ref} className={`shop-psitem ${inView && "shop-psitem_inview"}`}>
      <Link to={game.url}>
        <div
          className={`shop-item__img-wrapper ${
            isImgLoaded && "shop-item__img-wrapper_loaded"
          }`}
        >
          <img
            className={`shop-item__img`}
            loading="lazy"
            src={game.img}
            onLoad={() => setIsImgLoaded(true)}
            alt={`${game.title} cover`}
          />
        </div>
        <div className="shop-item__details__shadow"></div>
        <div className="shop-item__details">
          <div className="shop-item__details-title">
            <span>{game.title}</span>
          </div>
          <div className="shop-item__details-price-wrp">
            {/* <p className="shop-item__details-platform">PS4/PS5</p> */}
            <span>
              {" "}
              {game.isOnline && 'от '}{minPrice}
              {currency}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
});

export default memo(ShopPSItem);
