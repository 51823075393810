import { useEffect } from "react";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
// import Header from "./Components/Header";
import Header from "./Components/Header/Header";
import AnimatedRouts from "./Routes/AnimatedRouts";
import { HelmetProvider } from "react-helmet-async";
import MobileMenu from "./Components/MobileMenu/MobileMenu";
import Footer from "./Components/Footer";
import ScrollToTop from "./Utils/ScrollTop";
import Other from "./store/Other";
// import ReactPixel from "react-facebook-pixel";
import LoginSidebar from "./Components/Auth/LoginSidebar";
import Snowfall from "react-snowfall";
import RegUser from "./store/RegUser";


function App() {
  useEffect(() => {
    Other.defineCountry();
    // Shop.fetchGames();
    if (localStorage.getItem("userId") !== null) {
      Other.auth(localStorage.getItem("userId") as string);
    }
  }, []);

  useEffect(() => {
    RegUser.getUserData(null);
  }, [Other.isAuth]);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Header />
        {/* {!window.matchMedia("(max-width: 700px)").matches && (
          <Snowfall snowflakeCount={8} />
        )} */}
        <div className="wrpr">
          <ScrollToTop />
          <LoginSidebar />
          <MobileMenu />
          <AnimatedRouts />
        </div>
        <Footer />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
