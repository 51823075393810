import { FC, memo } from "react";

interface props {
  title: string;
  // methodId: number;
  img?: string;
  imgFromFront?: string;
  isSelected: boolean;
  onClick: () => void;
}

const PaymentMethod: FC<props> = ({
  isSelected,
  img,
  imgFromFront,
  title,
  onClick,
}) => {
  return (
    <div
      className="payment-method"
      onClick={onClick}
      id={isSelected ? "payment-method_selected" : ""}
    >
      <img src={imgFromFront ? imgFromFront : img} />
      <span className="payment-method__title">{title}</span>
    </div>
  );
};

export default memo(PaymentMethod);
