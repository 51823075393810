const P2PTip = ({ text, num, currNumb, setVisibleTip }: any) => {
  return (
    <div
      style={{ display: num !== currNumb ? "none" : "" }}
      className="payment-details__main__tip"
    >
      <div className="tip__text">{text}</div>
      <div className="tip__btns">
        {currNumb < 3 && (
          <div
            onClick={() => {
              setVisibleTip(-1);
            }}
            className="tip__btn"
          >
            Пропустить
          </div>
        )}
        <div
          onClick={() => {
            setVisibleTip(currNumb + 1);
          }}
          className="tip__btn"
        >
          {currNumb < 3 ? "Дальше" : "Понял"}
        </div>
      </div>
    </div>
  );
};

export default P2PTip;
