import React from "react";

interface ContactsProps {
  contantsVisibility: boolean;
  setContantsVisibility: any;
}

const Contacts: React.FC<ContactsProps> = ({
  contantsVisibility,
  setContantsVisibility,
}) => {
  return (
    <div
      style={
        contantsVisibility ? { opacity: "1" } : { opacity: 0, right: "-350px" }
      }
      className="contacts__container"
    >
      <span onClick={() => setContantsVisibility(false)} className="close">
        x
      </span>
      <div onClick={(e) => e.stopPropagation()} className="inf">
        <p>
          <span>Email</span> steamrent.bot@gmail.com
        </p>

        <p>
          <span>Telegram:</span> <a href="https://t.me/steam_rents">Telegram</a>
        </p>
        <p>
          <span>Время работы:</span> 10:00-22:00
        </p>
        <p>
          <span>А также чат на нашем сайте</span>
        </p>
      </div>
    </div>
  );
};

export default React.memo(Contacts);
