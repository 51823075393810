import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IGame } from "../../types/types";
import "./GamePage.scss";
import Other from "../../store/Other";
import { observer } from "mobx-react-lite";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import GameImgSlider from "./GameImgSlider";
import GameAbout from "./GameAbout";
import SimilarGames from "./SimilarGames";
import RentalDurationSelect from "./RentalDurationSelect/RentalDurationSelect";
import PrepaymentForm from "../../Components/Product/PrepaymentForm/PrepaymentForm";
import Product from "../../store/Product";
import Seo from "../../Components/Seo";
import GameType from "./GameType/GameType";
import { toJS } from "mobx";

const GamePageNRent = observer(() => {
  const navigate = useNavigate()
  const { gameId } = useParams();
  const { currency ,isAuth,openSideBar,logForServer} = Other;
  const {setCurrentGame,setSelectedOption,isGameAvailable,currentGame} = Product
  const [dlc,SetDlc] = useState(false)
  const [product, setProduct] = useState<IGame | null>(null);
  const [mnPrice,setMnPrice] = useState<number | null>(null)
  const [prepaymentBlockVisibility,setPrepaymentBlockVisibility] = useState(false)
  const [isAvilable,setIsAvilable] = useState<boolean>(true)

  

  const fetchGame = async() => {  
    console.log('same')
    if(currentGame?.id == gameId){
        if( currentGame !== null && currentGame?.online?.accounts[0].willBeAvilable as number > Date.now()){
            setIsAvilable(false)
            logForServer({title:currentGame.title,action:'RENT'})
        }
        return
      }  
    try {
        console.log('fetch')
   const product = await  axios
   .post("https://api.steam-rent.com/getGameById", { gameId })
   .then((resp) => resp.data.game[0]);
   setProduct(product)
   setCurrentGame(product)
   if(product.willBeAvilable > Date.now()){
    setIsAvilable(false)
   }

   if(product.dlc && product?.dlc?.length > 0){
    SetDlc(true)
   }

    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    currentGame?.id !== gameId && fetchGame()
  }, []);

  



  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
    {currentGame !== null &&
      <>
      {currentGame?.searchQuery ?
        <Seo title={currentGame.searchQuery} description={`Хотите поиграть в ${currentGame.title} прямо сейчас? Арендуйте ${currentGame.platform} аккаунт с игрой в нашем магазине. Удобные методы оплаты и приятные цены ждут вас!`}
        schemaMarkup={{name:currentGame.title,brand:String(currentGame.publisher),description:currentGame.description,imageUrl:currentGame.img,price:currentGame.price.usdPerMonth,sku:String(`${currentGame.id}rnt`)}}
        />
        :
        <Seo title={`Аренда аккаунта ${currentGame.title} ${currentGame.platform}`}
        description={`Арендуйте ${currentGame.platform} аккаунт с игрой ${currentGame.title} без необходимости ждать. Удобные методы оплаты и приятные цены ждут ваc`} 
        schemaMarkup={{name:currentGame.title,brand:String(currentGame.publisher),description:currentGame.description,imageUrl:currentGame.img,price:currentGame.price.usdPerMonth,sku:String(`${currentGame.id}rnt`)}}
      />  
        }
      </>
    }
   <div className="game-pagee"
   onClick={() => navigate('/shop')}>
     <div className="game-page__wrapper">
      <div
       className="game-page__background"
       style={currentGame?{background:`linear-gradient(${currentGame.bcgColor},black)`}:{}}></div>
       
       <div
         className="content-container"
         onClick={(e) => e.stopPropagation()}
          >
                  {currentGame && <GameType product={currentGame}/>}

          <div className="content-container-wrp">
            <div className="content-container__left ">
            <div className="content-container__left__img ">
              {currentGame && <img src={currentGame.img} />}
              {currentGame?.metaRating && (
                <div className="content-container__left__metacritic">
                  <span> {currentGame.metaRating}</span>
                  <p>Metacritic</p>
                </div>
              )}
            </div>
            <div className="content-container__left-details">
                <div className="content-container__left-details__item">
                    <dt>Жанр</dt>
                    <dd>{currentGame && currentGame.genre ? currentGame.genre.map(gnr => `${gnr}  `) : <Skeleton />}</dd>
                  </div>
  
                  <div className="content-container__left-details__item">
                    <dt>Дата выхода</dt>
                    <dd>{currentGame ? currentGame.releaseDate : <Skeleton />}</dd>
                  </div>
                  <div className="content-container__left-details__item">
                    <dt>Издатель</dt>
                    <dd>{currentGame ? currentGame.publisher : <Skeleton />}</dd>
                  </div>
            </div>
            {/* <GameFeatures/> */}
            {currentGame?.relatedProductIds && <SimilarGames relatedProductIds={currentGame.relatedProductIds}/>}
            </div>
            <div className="content-container__right">
              <div className="content-container__right-details">
                  <img
                    src={require("../../assets/img/emptyHeart.png")}
                    id="favorite"
                    onClick={() =>
                      isAuth
                        ? navigate("/userpage/favorites")
                        : openSideBar(true)
                    }
                  />

                <div className="content-container__right-details__title">
                    <h1>
                      {currentGame ? `${currentGame.title} Аренда` : <Skeleton count={2}/>}
                    </h1>
                </div>
                  
                <div className="content-container__right-details__availability">
                 {/* <li> Доступен для аренды</li> */}
                 {/* <li>Есть В наличии</li> */}
            
                 {isAvilable ?<li>Есть В наличии</li> :<li>Сейчас занят</li>
 }
                 <li>Аренда аккаунта</li>               {dlc  && <li>Есть DLC</li>}

                </div>
                {currentGame &&  <RentalDurationSelect pricee={toJS(currentGame?.online?.price)} setMnPrice={setMnPrice} online={true}/>
              }
                  <div className="content-container__right-details__price">
                    <button onClick={() => isAvilable ?  setPrepaymentBlockVisibility(true) :alert('Аккаунт сейчас занят')}>
                      КУПИТЬ
                    </button>

                    {currentGame ? (
                    <span>
                      {'от'} {mnPrice} {currency}
                    </span>
                    ) : (
                      <Skeleton />
                    )}
                  </div>

                  <div className="content-container__right-details__subifo">
                    <div className="content-container__right-details__subifo__item">
                      <p>Поддержка языков</p>
                      <span className="subinfo-type__answ">
                        {currentGame ? currentGame.language : <Skeleton />}
                      </span>
                    </div>
                    <div className="content-container__right-details__subifo__item">
                      <p className="subinfo-type">
                        Тип
                        <div className="subinfo-type_tip">
                          {currentGame?.isOnline ?
                        <p>
                            Приобретая игру с данной меткой, покупатель будет
                            единственным пользователем аккаунта на весь
                            указанный срок
                          </p>
                          :
                          <p>Игры с этой меткой предназначены для одиночного прохождения. На одном аккаунте может быть несколько человек, но чтобы никто не мешал, просто нажмите "Steam - перейти в автономный режим" в левом верхнем углу и наслаждайтесь игрой.</p>

                        }                         
                        </div>
                      </p>
                      <span className="subinfo-type__answ">
                        {currentGame ? ("Online")
                            : (
                          <Skeleton />
                        )}
                      </span>
                    </div>
                    <div className="content-container__right-details__subifo__item">
                      <p>Платформа</p>
                      <span className="subinfo-type__answ">
                        {currentGame ? (
                         currentGame.platform
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </div>
                  </div>

                  {currentGame && currentGame.autoguard && <div className="content-container__right-details__message">
                  <img src={require("../../assets/img/lightning.png")} alt="" />   <p>Для этой игры доступно автоматическое получение кода</p>
                </div>}
                {currentGame && currentGame.free && <div className="content-container__right-details__message">
                  <img className="img-free" src={'https://img.icons8.com/external-vitaliy-gorbachev-fill-vitaly-gorbachev/60/FA5252/external-free-sales-vitaliy-gorbachev-fill-vitaly-gorbachev.png'} alt="" />   <p>Иногда мы бесплатно даем поиграть</p>
                </div>}
              </div>
              <GameImgSlider
                    screenshots={currentGame ? currentGame.screenshots : [null, null]}
                    video={currentGame ? currentGame.video : null}
                  />
            </div>
          </div>
          <GameAbout product={currentGame} onlineVersion={true}/>
        </div>
        
    </div>
   </div>
   {prepaymentBlockVisibility && (
          <PrepaymentForm
            display={prepaymentBlockVisibility}
            setDispay={setPrepaymentBlockVisibility}
            isOnlineVersion={true}
          />
        )}
  </SkeletonTheme>
  );
});



export default GamePageNRent;
