import { FC } from "react";
import { IRegUserPaymentData } from "../../types/types";

interface props {
  paymentData: IRegUserPaymentData;
}

const PaymentItem: FC<props> = ({ paymentData }) => {
  return (
    <div className="user-page__container-payments__list-item">
      <p className="payment-title">
        <span>{paymentData.title}</span>
        {/* <em style={{ marginLeft: "8px" }}>(offline)</em> */}
        {paymentData.isOnline ? (
          <span className="payment-isOnline payment-isOnline_online">
            (online)
          </span>
        ) : (
          <span className="payment-isOnline ">(offline)</span>
        )}
      </p>
      <p>{paymentData.duration}</p>
      <p>{paymentData.date}</p>
      <p>
        {paymentData.amount}
        {paymentData.currency}
        <span
          className="payment-userd-cashback"
          style={{
            visibility: paymentData.usedCashback == 0 ? "hidden" : "visible",
          }}
        >
          ( -{paymentData.usedCashback}$)
        </span>
      </p>
      <p>
        +{paymentData.newCashback} {paymentData.currency}
      </p>
    </div>
  );
};

export default PaymentItem;
